import { FilterI } from "components/Filter/types";
import { PriceFilter } from "Items/types/price";

export const createFilterStructure = (
  params: PriceFilter,
  options: any
): FilterI[] => {
  return [
    {
      type: "autocomplete-client",
      name: "client",
      label: "Cliente",
      active: params?.client !== undefined,
      value: params?.client,
    },
    {
      type: "options",
      name: "charge_type",
      label: "Tipo addebito",
      options: options.charge_type.choices as {
        value: any;
        display_name: string;
      }[],
      active: params?.charge_type !== undefined,
      value: params?.charge_type,
    },
    {
      type: "autocomplete-item",
      name: "item",
      label: "Articolo",
      active: params?.item !== undefined,
      value: params?.item,
    },
    {
      type: "date",
      name: "date",
      label: "Data inizio validità",
      active: params?.date !== undefined,
      value: params?.date,
    },
    {
      type: "options",
      name: "quantity_type",
      label: "Tipo quantità",
      options: options.quantity_type.choices as {
        value: any;
        display_name: string;
      }[],
      active: params?.quantity_type !== undefined,
      value: params?.quantity_type,
    },
    {
      type: "boolean",
      name: "price_type",
      label: "Listino cliente",
      active: params?.date !== undefined,
      value: params?.date,
    },
  ];
};
