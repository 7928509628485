import { useMemo } from "react";
import { Copy, Package as PackageIcon, Printer } from "react-feather";
import { UseQueryOptions, useMutation, useQueryClient } from "react-query";
import { Button } from "reactstrap";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { DELIVERIES_API, NOTES_API } from "utils/constants";
import { Delivery, DeliveryByAreaReport } from "../types/delivery";
import {
  getDisplayDate,
  getDisplayOnlyDate,
  getDisplayValue,
} from "utils/helpers";
import { DeleteButton } from "components/buttons/DeleteButton";
import { EditButton } from "components/buttons/EditButton";
import { IconButton } from "components/buttons/IconButton";
import { DownloadFile } from "components/DownloadFile";
import { useGlobalModals } from "shared/context/globalModals";
import { filterJson } from "components/tables/filters";
import { AxiosRequestConfig } from "axios";
import { client } from "utils/auth-client";
import { getSelectableColumn } from "components/tables/selectableColumn";

const key = "deliveries";
const url = DELIVERIES_API;

export function useOptionsDelivery() {
  return useOptions(url, key);
}

export function useAddDelivery() {
  return useAdd<Delivery>(url, key);
}

export function useDeleteDelivery() {
  return useDelete(url, key);
}

export function useUpdateDelivery() {
  return useUpdate<Delivery>(url, key);
}

export function useListDelivery(
  params?: any,
  config?: UseQueryOptions<any, unknown, Delivery[]>
) {
  return useList(url, key, params, config);
}
export function useReportDeliveriesByArea(
  params?: any,
  config?: UseQueryOptions<any, unknown, DeliveryByAreaReport>
) {
  return useList(`${url}get_report_by_area/`, key, params, config);
}
export function useSearchDeliveries(params: any) {
  return useSearchPaginated<Delivery>(url, key, params, {
    initialData: {
      results: [] as Delivery[], count: 0,
      with_note_no_final: 0, with_note_final: 0
    },
  });
}

export function useForceConsignmentStatus(requestConfig?: AxiosRequestConfig) {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { ids: number[] }) => {
      return client(`${DELIVERIES_API}force_consignment_status/`, {
        method: "POST",
        data: data,
        ...(requestConfig ? requestConfig : {}),
      });
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([key], {});
      },
    }
  );
}

export function useConsignmentToTraction(requestConfig?: AxiosRequestConfig) {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { ids: number[] }) => {
      return client(`${DELIVERIES_API}consign_to_traction/`, {
        method: "POST",
        data: data,
        ...(requestConfig ? requestConfig : {}),
      });
    },
    {
      onSuccess: () => {
        //queryClient.refetchQueries([key], {});
      },
    }
  );
}

export function useBackDeliveries(requestConfig?: AxiosRequestConfig) {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { ids: number[] }) => {
      return client(`${DELIVERIES_API}back_deliveries/`, {
        method: "POST",
        data: data,
        ...(requestConfig ? requestConfig : {}),
      });
    },
    {
      onSuccess: () => {
        //queryClient.refetchQueries([key], {});
      },
    }
  );
}

export function useResendNotes(requestConfig?: AxiosRequestConfig) {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { ids: number[] }) => {
      return client(`${DELIVERIES_API}resend_notes/`, {
        method: "POST",
        data: data,
        ...(requestConfig ? requestConfig : {}),
      });
    },
    {
      onSuccess: () => {
        //queryClient.refetchQueries([key], {});
      },
    }
  );
}

export function useResendNotesText(requestConfig?: AxiosRequestConfig) {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { text: String }) => {
      return client(`${DELIVERIES_API}resend_notes_text/`, {
        method: "POST",
        data: data,
        ...(requestConfig ? requestConfig : {}),
      });
    },
    {
      onSuccess: () => {
        //queryClient.refetchQueries([key], {});
      },
    }
  );
}

export function useDelivery(
  id: string | number,
  config?: UseQueryOptions<any, unknown, Delivery>
) {
  return useGetItem<Delivery>(id, url, key, config);
}

export function useDeliveryColumns(
  setShowPackages: (id: number) => void,
  cloneDelivery: (delivery: Delivery) => void,
  options: any
) {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteDelivery } = useDeleteDelivery();
  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteDelivery(id);
    };
    return [
      getSelectableColumn(),
      {
        accessor: "__id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">
              {cell.row.original.editable && (
                <DeleteButton
                  permissions={["rosina.change_delivery"]}
                  onClick={() => {
                    onDelete(cell.row.original.id);
                  }}
                />
              )}
              <EditButton
                onClick={() => setActiveItem("delivery", cell.row.original.id)}
                permissions={[]}
              />
              <IconButton
                Icon={PackageIcon}
                title={"Mostra articoli"}
                onClick={() => setShowPackages(cell.row.original.id)}
              />
              <IconButton
                disabled={!cell.row.original.receiver_id}
                Icon={Copy}
                title={"Clona"}
                onClick={() => cloneDelivery(cell.row.original)}
              />
            </div>
          );
        },
      },
      {
        accessor: "id",
        Header: "ID",
        sortType: "basic",
      },
      {
        Header: "Forza",
        accessor: "force",
        sortType: "basic",
        Cell: (cell: any) =>
          getDisplayValue("force", cell.value, options),
      },
      {
        accessor: "document_number",
        Header: "Numero documento",
        sortType: "basic",
        Cell: (cell: any) =>
          getDisplayValue("document_number", cell.value, options),
      },
      {
        accessor: "type",
        Header: "Tipo",
        sortType: "basic",
        Cell: (cell: any) => getDisplayValue("type", cell.value, options),
      },
      {
        accessor: "created_date",
        Header: "Data di creazione",
        sortType: "basic",
        Cell: (cell: any) => getDisplayDate(cell.value),
      },
      {
        accessor: "delivery_date",
        Header: "Data missione",
        sortType: "basic",
        Cell: (cell: any) => getDisplayDate(cell.value),
      },
      {
        Header: "Cliente",
        accessor: "client",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.client)
            return (
              <Button
                title={"Mostra soggetto"}
                color="link"
                onClick={() =>
                  setActiveItem("subject", cell.row.original.client.id)
                }
              >{`${cell.row.original.client.id}: ${cell.row.original.client.business_name}`}</Button>
            );
          return "";
        },
        filter: filterJson
      },
      {
        Header: "Destinatario",
        accessor: "receiver",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.receiver)
            return (
              <Button
                title={"Mostra soggetto"}
                color="link"
                onClick={() =>
                  setActiveItem("subject", cell.row.original.receiver.id)
                }
              >{`${cell.row.original.receiver.id}: ${cell.row.original.receiver.business_name}`}</Button>
            );
          return "";
        },
        filter: filterJson
      },
      {
        Header: "Peso",
        accessor: "weight",
        sortType: "basic",
      },
      {
        Header: "Numero colli",
        accessor: "packages_number",
        sortType: "basic",
      },
      {
        Header: "Bolla inviata",
        accessor: "bill_sent",
        sortType: "basic",
        Cell: (cell: any) => getDisplayDate(cell.value),
      },
      {
        Header: "Bolla inviata (1° invio)",
        accessor: "first_bill_sent",
        sortType: "basic",
        Cell: (cell: any) => getDisplayDate(cell.value),
      },
      {
        accessor: "note_set",
        Header: "Bolle",
        sortType: "basic",
        Cell: (cell: any) => (
          <div className="d-flex flex-column">
            {cell.value
              ? cell.value.map((value: any) => (
                <DownloadFile
                  key={value.id}
                  title={value.file}
                  final={value.final}
                  urlDownload={`${NOTES_API}${value.id}/download/`}
                />
              ))
              : ""}
          </div>
        ),
      },
      {
        accessor: "print_with_qr",
        Header: "",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <DownloadFile
              title={"Stampa bolla"}
              urlDownload={`${DELIVERIES_API}print_with_qr/${cell.row.original.id}`}
            />
          );
        },
      },
      {
        Header: "Indirizzo",
        accessor: "address",
        sortType: "basic",
      },
      {
        Header: "Località",
        accessor: "locality",
        sortType: "basic",
      },
      {
        Header: "Cap",
        accessor: "postal_code",
        sortType: "basic",
      },
      {
        Header: "Provincia",
        accessor: "province",
        sortType: "basic",
      },
      {
        Header: "Stato",
        accessor: "status",
        sortType: "basic",
        Cell: (cell: any) => getDisplayValue("status", cell.value, options),
      },
      {
        Header: "Sequenza",
        accessor: "sequence",
        sortType: "basic",
      },
      {
        Header: "Data documento",
        accessor: "document_date",
        sortType: "basic",
        Cell: (cell: any) => getDisplayOnlyDate(cell.value),
      },
      {
        Header: "Data fatturazione",
        accessor: "invoice_date",
        sortType: "basic",
        Cell: (cell: any) => getDisplayOnlyDate(cell.value),
      },
      {
        Header: "File",
        accessor: "import_file",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.value)
            return (
              <Button
                title={"Mostra file"}
                color="link"
                onClick={() => setActiveItem("import", cell.value)}
              >
                {cell.value}
              </Button>
            );
          return "";
        },
      },
      {
        Header: "Giro",
        accessor: "route_id",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.value)
            return (
              <Button
                title={"Mostra giro"}
                color="link"
                onClick={() => setActiveItem("route", cell.value)}
              >
                {cell.value}
              </Button>
            );
          return "";
        },
      },
      {
        Header: "Nascondi missione",
        accessor: "hide",
        sortType: "basic",
        Cell: (cell: any) => getDisplayValue("hide", cell.value, options),
      },
      {
        Header: "Note",
        accessor: "notes",
        sortType: "basic",
      },
    ];
  }, [cloneDelivery, deleteDelivery, options, setActiveItem, setShowPackages]);
}
