import L, { LatLngExpression } from "leaflet";
import { useEffect, useMemo, useRef, useState } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { Rnd } from "react-rnd";
import Sidebar from "react-sidebar";
import { arrayToFloat } from "utils/helpers";
import { useDeliveriesPlanner } from "./hooks/useDeliveriesPlanner";
import { DeliveryGroup } from "./types";
import "./DeliveryPlanner.css";
import { Button, Nav, NavItem, NavLink } from "reactstrap";
import {
  ArrowRight,
  Edit,
  Plus,
  Printer,
  Rewind,
  Send,
  TrendingUp,
  ChevronsDown,
  ChevronsUp,
  ChevronUp,
  ChevronsLeft
} from "react-feather";
import {
  blueIcon,
  purpleIcon,
  redIcon,
  orangeIcon,
  yellowIcon,
  orangeBlueIcon,
  orangeGreenIcon,
  greenIcon,
  redNIcon,
  blueNIcon,
  yellowNIcon,
  orangeNIcon,
  greenNIcon,
  orangeGreenNIcon,
  orangeBlueNIcon,
} from "../components/Icons";
import { Routing } from "../components/Routing";
import { ModalVehicle } from "../components/ModalVehicle";
import { useToggler } from "shared/hooks/useToggler";
import { TableGroupedDeliveries } from "./components/TableGroupedDeliveries";
import { ModalDriver } from "../components/ModalDriver";
import { toast } from "react-toastify";
import { LoadingScreen } from "components/LoadingScreen";
import classnames from "classnames";
import { ModalRoute } from "./components/ModalRoute";
import { client } from "utils/auth-client";
import { AUTO_ORDER_API, STORE_POSITION } from "utils/constants";
import { Link } from "react-router-dom";

import { deliveriesContainsOnlyWithdrawals, deliveriesNotContainsOnlyWithdrawals, deliveryContainsNotes } from "./utils";
import { Pill } from "components/Pill";
import { useAddRoute, useUpdateRoute } from "Distribution/hooks/route";
import { Route } from "Distribution/types/route";
import { PopupTable } from "./components/PopupTable";
import { useStore } from "./store";
import { Delivery } from "Distribution/types/delivery";
import _ from "lodash";
import { useQueryClient } from "react-query";
import React from "react";
export const DeliveryPlanner = () => {
  //const [state, dispatch] = useDeliveryPlannerReducer();
  const { toggle, value: isOpen, setTrue: openModal } = useToggler();
  const { mutateAsync: addRoute } = useAddRoute();
  const { mutateAsync: updateRoute } = useUpdateRoute();

  const [hideWithdrawals, setHideWithdrawals] = useState<boolean>(false);

  const {
    toggle: toggleDriver,
    value: isOpenDriver,
    setTrue: openModalDriver,
  } = useToggler();
  const {
    toggle: toggleRoute,
    value: isOpenRoute,
    setTrue: openModalRoute,
  } = useToggler();

  const divDeliveries = useRef<Rnd>(null);
  const endTableRef = useRef<HTMLTableRowElement>(null);
  const startTableRef = useRef<HTMLDivElement>(null);
  const endRef = useRef<HTMLDivElement>(null);
  const startRef = useRef<HTMLDivElement>(null);

  const scrollToTableBottom = () => {
    endRef.current?.scrollIntoView({ behavior: "smooth" });
    endTableRef.current?.scrollIntoView({ behavior: "smooth" });
  }
  const centerToTable = () => {
    endRef.current?.scrollIntoView({ behavior: "smooth" });
  }
  const scrollToTableTop = () => {
    startTableRef.current?.scrollIntoView({ behavior: "smooth" });
  }
  const scrollToPageTop = () => {
    startRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  const [isLoading, setIsLoading] = useState(true);

  const {
    addedDeliveries,
    editMode,
    driver,
    vehicle,
    clusters,
    groupedDeliveries,
    activeClusterKey,
    routes,
    selectedRoute,
    colors,
    focusCluster,
    initPlanner,
    toggleEditMode,
    setActiveClusterKey,
    resetActiveClusterKey,
    addDeliveries,
    deleteDeliveries,
    toggleDelivery,
    reorderGroupedDeliveries,
    updateSelectedDriver,
    updateSelectedVehicle,
    updateSelectedRoute,
    toggleSelectedRoutes,
    onActiveRouteChange,
    setGroupedDeliveries,
  } = useStore();
  const queryClient = useQueryClient();
  const {
    refetch,
    data: deliveries,
    isFetching,
  } = useDeliveriesPlanner(editMode ? routes : [], {
    onSettled: () => {
      setIsLoading(false);
    },
  });
  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);
  useEffect(() => {
    initPlanner(deliveries!);
  }, [initPlanner, deliveries]);

  useEffect(() => {
    onActiveRouteChange(editMode, selectedRoute);
  }, [onActiveRouteChange, editMode, selectedRoute, routes]);

  const points = useMemo(() => {
    const points = [];
    points.push(L.latLng(STORE_POSITION.latitude, STORE_POSITION.longitude));
    groupedDeliveries.forEach((delivery) => {
      points.push(L.latLng(delivery.latitude, delivery.longitude));
    });
    points.push(L.latLng(STORE_POSITION.latitude, STORE_POSITION.longitude));
    return points;
  }, [groupedDeliveries]);

  useEffect(() => {
    if (points.length <= 0) {
      setTotKm(0);
    }
  }, [points]);

  const getClusterColor = (
    cluster: {
      deliveries: Delivery[];
      focused: boolean;
    },
    addedDeliveries: Delivery[],
    onlyWithdrawal: boolean,
    notContainWithdrawal: boolean,
    containsNotes: boolean
  ) => {
    const totalWeight = cluster.deliveries
      ? cluster.deliveries.reduce((result, delivery) => {
        if (delivery.weight) result += delivery.weight;
        return result;
      }, 0)
      : 0;

    if (cluster.focused && containsNotes) return yellowNIcon;
    if (cluster.focused) return yellowIcon;
    const segmentsInCommon = _.intersectionBy(
      addedDeliveries,
      cluster.deliveries,
      "id"
    );

    const active = segmentsInCommon.length > 0;
    if (active && containsNotes) return redNIcon;
    if (active) return redIcon;
    if (onlyWithdrawal && containsNotes) return orangeNIcon;
    if (onlyWithdrawal) return orangeIcon;
    if (totalWeight >= 300 && notContainWithdrawal && containsNotes) return greenNIcon;
    if (totalWeight >= 300 && notContainWithdrawal) return greenIcon;
    if (totalWeight >= 300 && containsNotes) return orangeGreenNIcon;
    if (totalWeight >= 300) return orangeGreenIcon;
    if (notContainWithdrawal && containsNotes) return blueNIcon;
    if (notContainWithdrawal) return blueIcon;
    if (containsNotes) return orangeBlueNIcon;
    return orangeBlueIcon;

  };

  const openPopup = (key: string, x: number, y: number) => {
    if (divDeliveries.current)
      divDeliveries.current.updatePosition({ x: x, y: y });
    setActiveClusterKey(key);
  };

  const getAddedDeliveriesIds = () => {
    const deliveries: number[] = [];
    groupedDeliveries.forEach((group) => {
      addedDeliveries.forEach((delivery) => {
        const key = `${delivery.receiver!.latitude},${delivery.receiver!.longitude
          }`;

        if (key === group.id) {
          deliveries.push(delivery.id!);
        }
      });
    });
    return deliveries;
  };

  const saveRoute = () => {
    if (vehicle && driver && groupedDeliveries.length > 0) {
      if (editMode && selectedRoute === undefined) {
        toast.warning("Seleziona un giro da modificare");
      } else {
        setIsLoading(true);
        const deliveriesId: number[] = getAddedDeliveriesIds();
        const data: Route & { deliveries_ids: number[] } = {
          deliveries_ids: deliveriesId,
          driver_id: driver.id,
          vehicle_id: vehicle.id,
          total_distance: totKm,
          round_order: 0,
        };
        if (selectedRoute) {
          updateRoute({ newValues: data, id: selectedRoute.id! })
            .then(() => {
              queryClient.invalidateQueries(["routes"]);
              setIsLoading(false);
              toast.success("Fatto");
              refetch();
            })
            .catch(() => {
              setIsLoading(false);
              toast.error("Errore");
            });
        } else {
          addRoute(data)
            .then(() => {
              setIsLoading(false);
              toast.success("Fatto");
              refetch();
            })
            .catch(() => {
              setIsLoading(false);
              toast.error("Errore");
            });
        }
      }
    } else {
      toast.warning(
        "Assicurati di aver selezionato autista, mezzo e missioni!",
        { position: "top-left" }
      );
    }
  };

  const autoOrderDeliveries = () => {
    const deliveries = addedDeliveries;
    if (deliveries.length > 0) {
      setIsLoading(true);
      client(AUTO_ORDER_API, {
        method: "POST",
        data: deliveries,
      })
        .then((response) => {
          setIsLoading(false);
          const newGroupedData: DeliveryGroup[] = [];
          response.forEach((point: { lat: number; lon: number }) => {
            const key = `${point.lat},${point.lon}`;
            const deliveryGroup = groupedDeliveries.find((x) => x.id === key);
            if (deliveryGroup) newGroupedData.push(deliveryGroup);
          });
          setGroupedDeliveries(newGroupedData);
        })
        .catch(() => {
          setIsLoading(false);
          toast.error("Erorre ordinamento");
        });
    }
  };
  const reverseOrder = () => {
    setGroupedDeliveries([...groupedDeliveries].reverse());
  };
  const mainStats = useMemo(() => {
    return groupedDeliveries.reduce(
      (previous, current) => {
        return {
          leftWeight: previous.leftWeight - current.weight!,
          totalPackages: previous.totalPackages + current.packages_number!,
        };
      },
      {
        leftWeight: vehicle ? vehicle.liters! : 0,
        totalPackages: 0,
      }
    );
  }, [groupedDeliveries, vehicle]);
  const [totKm, setTotKm] = useState(0);

  const messagesEndRef = React.createRef()

  const [lastPointView, setLastPointView] = useState("");

  return (
    <>
      <LoadingScreen visible={isLoading} />

      <Sidebar
        sidebar={
          <>
            <div style={{ overflowY: "auto", height: "100%" }}>
              <div ref={startRef}></div>
              <div style={{ marginBottom: "60px" }} className="px-4 pt-2 ">
                <div className="d-flex flex-row justify-content-between mb-3">
                  <label style={{ fontSize: "1.2rem" }}>
                    <input
                      type="checkbox"
                      onChange={() => {
                        setHideWithdrawals((value) => !value);
                      }}
                      checked={hideWithdrawals}
                    />
                    &nbsp;
                    <label>
                      <h6>Nascondi Ritiri</h6>
                    </label>
                  </label>

                  <Link
                    style={{ color: "#7166f9", fontSize: "1.2rem" }}
                    to="/login"
                    className="d-flex flex-row justify-content-center align-items-baseline"
                  >
                    Home
                    <span>
                      <ArrowRight className="ml-2" size={15} />
                    </span>
                  </Link>
                </div>
                <div className="d-flex flex-row justify-content-between card__stats__container ">
                  <div className="card__stats">
                    <div>
                      <span className="h1">
                        {Math.round(mainStats.leftWeight)} kg
                      </span>
                      <h6>Peso rimanente</h6>
                    </div>
                  </div>
                  <div className="card__stats">
                    <div>
                      <span className="h1">{mainStats.totalPackages}</span>
                      <h6>Tot. colli</h6>
                    </div>
                  </div>
                  <div className="card__stats">
                    <div>
                      <span className="h1">{Math.round(totKm)} km</span>
                      <h6>Tot. km</h6>
                    </div>
                  </div>
                </div>
                {editMode && (
                  <>
                    <div className="card__selection mt-5 mb-5">
                      <div className="d-flex justify-content-between align-items-center ">
                        <h6>Seleziona giri da visualizzare:</h6>
                        <div className="d-flex flex-row justify-content-end align-items-center">
                          <Button
                            title="Modifica"
                            onClick={openModalRoute}
                            className="edit-buttons ml-3"
                            color="warning"
                          >
                            <Edit size={13} />
                          </Button>
                        </div>
                      </div>
                    </div>
                    {routes.length > 0 && (
                      <>
                        <div className="card__selection d-flex flex-column justify-content-between align-items-center mt-5">
                          <div className="d-flex flex-row align-items-center justify-content-center">
                            {routes.map((route) => (
                              <Pill
                                onClick={() => updateSelectedRoute(route)}
                                color={
                                  selectedRoute && selectedRoute.id === route.id
                                    ? "active"
                                    : undefined
                                }
                                style={{ cursor: "pointer" }}
                                text={route.id!}
                                key={route.id}
                              />
                            ))}
                          </div>
                        </div>
                        <div className="vertical-line" />
                      </>
                    )}
                  </>
                )}

                <div
                  className={classnames(
                    "card__selection d-flex justify-content-between align-items-center",
                    { "mt-5": !editMode }
                  )}
                >
                  <h6>Automezzo:</h6>
                  <div className="d-flex flex-row justify-content-end align-items-center">
                    <b>{vehicle ? vehicle.plate : "-----"}</b>
                    <Button
                      title="Modifica"
                      onClick={openModal}
                      className="edit-buttons ml-3"
                      color="warning"
                    >
                      <Edit size={13} />
                    </Button>
                  </div>
                </div>

                <div className="vertical-line" />
                <div
                  className={
                    "card__selection d-flex justify-content-between align-items-center"
                  }
                >
                  <h6>Autista:</h6>
                  <div className="d-flex flex-row justify-content-end align-items-center">
                    <b>
                      {driver ? `${driver.name} ${driver.surname}` : "-----"}
                    </b>
                    <Button
                      title="Modifica"
                      onClick={openModalDriver}
                      className="edit-buttons ml-3"
                      color="warning"
                    >
                      <Edit size={13} />
                    </Button>
                  </div>
                </div>

                <div className="vertical-line" />
                <div className="d-flex flex-row justify-content-center align-items-center">
                  <Button
                    className="w-100 mr-1 d-flex flex-row justify-content-between align-items-center bg-primary-secondary"
                    color="primary"
                    onClick={autoOrderDeliveries}
                  >
                    Ordina
                    <TrendingUp size={20} />
                  </Button>
                  <Button
                    onClick={reverseOrder}
                    className="w-100 ml-1 d-flex flex-row justify-content-between align-items-center bg-primary-secondary"
                    color="primary"
                  >
                    Inverti
                    <Rewind size={20} />
                  </Button>
                </div>
                <div className="card__selection p-0 pb-3 mt-2">
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: "50%" }}>
                      <h6 className="pt-3 pl-3">Missioni:</h6>
                    </div>
                    <div style={{ display: "flex", flex: "50%", flexDirection: "row-reverse" }}>
                      <Button onClick={scrollToPageTop}><ChevronsUp size={15} /></Button>
                      <Button onClick={scrollToTableTop}><ChevronUp size={15} /></Button>
                      <Button onClick={centerToTable}><ChevronsLeft size={15} /></Button>
                      <Button onClick={scrollToTableBottom}><ChevronsDown size={15} /></Button>
                    </div>
                  </div>
                  <div
                    style={{ maxHeight: "600px", overflowY: "auto" }}
                    className="mt-3 "
                  >
                    <div ref={startTableRef}></div>
                    <hr style={{ backgroundColor: "red", height: "3px" }} />
                    <TableGroupedDeliveries
                      onOrdering={reorderGroupedDeliveries}
                      onDeleteItem={deleteDeliveries}
                      data={groupedDeliveries}
                      onRowClick={focusCluster}
                      onDrop={() => { }}
                      endTableRef={endTableRef}
                    />
                    <hr style={{ backgroundColor: "red", height: "3px" }} />
                  </div>
                </div>
                <div className="vertical-line" />

                <Button
                  block
                  color="primary"
                  className="d-flex flex-row justify-content-between align-items-center bg-primary-secondary mt-2"
                >
                  Stampa
                  <Printer size={20} />
                </Button>
                <Button
                  onClick={saveRoute}
                  block
                  color="success"
                  className="d-flex flex-row justify-content-between align-items-center bg-success-secondary mt-2"
                >
                  SALVA GIRO <Send size={20} />
                </Button>
                <div ref={endRef}></div>
              </div>
            </div>

            <div className="tab-menu">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: !editMode })}
                    onClick={() => {
                      toggleEditMode();
                    }}
                  >
                    <Plus size={20} />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: editMode })}
                    onClick={() => {
                      toggleEditMode();
                    }}
                  >
                    <Edit size={20} />
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </>
        }
        sidebarClassName={"bg-white"}
        docked={true}
        open={true}
        touch={false}
        pullRight={true}
        styles={{
          sidebar: { background: "white", width: "450px", zIndex: "1050" },
          overlay: { zIndex: "1040" },
        }}
        shadow={false}
      >
        <MapContainer
          whenCreated={(map) => {
            map.on("click", function () {
              resetActiveClusterKey();
            });
          }}
          center={[41.2925, 12.5736]}
          zoom={5}
          style={{ height: "100%" }}
          zoomControl={false}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          />
          <>
            <p style={{
              position: "absolute",
              zIndex: 1000,
              backgroundColor: "white",
              top: 10,
              left: 10,
              fontSize: 15,
            }} >Ultimo punto visto: {lastPointView}</p>
            <Marker
              icon={purpleIcon}
              position={[STORE_POSITION.latitude, STORE_POSITION.longitude]}
            />
            {Object.keys(clusters).map((key) => {
              const position = arrayToFloat(key.split(","));

              if (
                !hideWithdrawals ||
                !deliveriesContainsOnlyWithdrawals(clusters[key].deliveries)
              ) {
                return (
                  <Marker
                    icon={getClusterColor(clusters[key], addedDeliveries,
                      deliveriesContainsOnlyWithdrawals(clusters[key].deliveries),
                      deliveriesNotContainsOnlyWithdrawals(clusters[key].deliveries),
                      deliveryContainsNotes(clusters[key].deliveries)
                    )}
                    title={`${clusters[key].deliveries.length} missioni \n ${clusters[key].deliveries[0].receiver?.business_name}`}
                    alt={`${clusters[key].deliveries.length} missioni \n ${clusters[key].deliveries[0].receiver?.business_name}`}
                    key={`${key}`}
                    eventHandlers={{
                      click: (e) => {
                        const key = `${e.latlng.lat},${e.latlng.lng}`;
                        openPopup(key, e.containerPoint.x, e.containerPoint.y);
                      },
                      contextmenu: (e) => {
                        const key = `${e.latlng.lat},${e.latlng.lng}`;
                        addDeliveries(key, hideWithdrawals);

                        openPopup(key, e.containerPoint.x, e.containerPoint.y);
                        //setTimeout(resetActiveClusterKey, 2000);
                      },
                      mouseover: (e) => {
                        setLastPointView(`${clusters[key].deliveries.length} missioni \n ${clusters[key].deliveries[0].receiver?.business_name}`);
                      }
                    }}
                    position={position as LatLngExpression}
                  />
                );
              } else {
                return null;
              }
            })}
          </>
          <Routing
            onRoutesFound={(distance: number) => {
              setTotKm(distance);
            }}
            points={points}
          />
          {deliveries &&
            editMode &&
            routes.map((route, index) => {
              if (!selectedRoute || selectedRoute.id !== route.id) {
                const deliveriesFiltered = deliveries
                  .filter((delivery) => delivery.route_id === route.id)
                  .sort((a, b) => {
                    if (a.sequence! < b.sequence!) {
                      return -1;
                    }
                    if (a.sequence! > b.sequence!) {
                      return 1;
                    }
                    return 0;
                  });
                const points = deliveriesFiltered.map((delivery) =>
                  L.latLng(
                    delivery.receiver!.latitude,
                    delivery.receiver!.longitude
                  )
                );
                if (deliveriesFiltered.length > 0) {
                  points.unshift(
                    L.latLng(STORE_POSITION.latitude, STORE_POSITION.longitude)
                  );
                  points.push(
                    L.latLng(STORE_POSITION.latitude, STORE_POSITION.longitude)
                  );
                }
                return (
                  <Routing
                    onRouteClick={() => {
                      updateSelectedRoute(route);
                    }}
                    color={colors[index]}
                    key={route.id}
                    points={points}
                  />
                );
              }
              return null;
            })}
        </MapContainer>
      </Sidebar>

      <PopupTable
        onAddClick={toggleDelivery}
        addedDeliveries={addedDeliveries}
        activeClusterKey={activeClusterKey}
        resetActiveClusterKey={resetActiveClusterKey}
        deliveries={
          activeClusterKey ? clusters[activeClusterKey].deliveries : []
        }
        selectedRoute={selectedRoute}
        hideWithdrawals={hideWithdrawals}
        ref={divDeliveries}
        deleteDeliveries={deleteDeliveries}
        addDeliveries={addDeliveries}
      />

      <ModalVehicle
        selectedVehicle={vehicle}
        setVehicle={updateSelectedVehicle}
        toggle={toggle}
        isOpen={isOpen}
      />
      <ModalDriver
        selectedDriver={driver}
        setDriver={updateSelectedDriver}
        toggle={toggleDriver}
        isOpen={isOpenDriver}
      />
      <ModalRoute
        routes={routes}
        setRoute={toggleSelectedRoutes}
        toggle={toggleRoute}
        isOpen={isOpenRoute}
      />
    </>
  );
};
