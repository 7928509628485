import { Filter } from "components/Filter";
import { Alert, Card, CardBody } from "reactstrap";
import { createFilterStructure } from "./filter";

import { Table } from "components/tables/Table";
import classNames from "classnames";

import { OrderPoint } from "Traction/types";
import { useOrderPointColumns } from "Traction/hooks/orderPoints";

interface Props {
  urlParamsObj: any;
  onSearch: (data: Partial<{ order: number }>) => void;
  orderpoints: OrderPoint[];
  page: number;
  pageCount: number;
  goToPage: (page: number) => void;
  options: any;
}

export const OrderPointListPage = ({
  urlParamsObj,
  onSearch,
  orderpoints,
  page,
  pageCount,
  goToPage,
  options,
}: Props) => {
  const columns = useOrderPointColumns(options);

  return options && (
    <>
      <div className="d-flex flex-row justify-content-between my-3">
        <Filter
          paramsObj={urlParamsObj}
          onFilterChange={onSearch}
          filterStructure={createFilterStructure(urlParamsObj)}
        />
      </div>
      <div className="row ">
        <div className={classNames("col-12")}>
          {orderpoints.length === 0 ? (
            <Alert color="primary">Nessuna tratta trovata </Alert>
          ) : (
            <Card>
              <CardBody className="p-1">
                <Table
                  pageIndex={page}
                  pageCount={pageCount}
                  setPage={(page: number) => goToPage(page)}
                  data={orderpoints}
                  columns={columns}
                  sortBy="date"
                />
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </>
  );
};
