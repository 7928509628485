import { useCallback, useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { client } from "utils/auth-client";
import { PAGE_SIZE } from "utils/constants";
import { useRef } from "react";

interface Props {
  disabled?: boolean;
  name: string;
  required?: boolean;
  defaultValue?: number | null | undefined;
  placeholder?: string;
  url: string;
  onChange: (value: number | null) => void;
}

export const AutocompleteNoController = ({
  disabled,
  onChange,
  name,
  required = false,
  defaultValue,
  placeholder,
  url,
}: Props) => {
  const _isMounted = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<{ id: number; label: string }[]>([]);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState<string>("");
  const [paginate, setPaginate] = useState<boolean>(true);

  useEffect(() => {
    return () => {
      // ComponentWillUnmount in Class Component
      _isMounted.current = false;
    };
  }, []);

  const handleSearch = useCallback(
    (query: string) => {
      if (_isMounted.current) {
        setIsLoading(true);
        setPage(1);
      }
      client(url, {
        method: "GET",
        params: { q: query, page: 1 },
      }).then((resp) => {
        const options = resp.results;
        if (_isMounted.current) {
          setPaginate(!!resp.next);
          setOptions(options);
          setIsLoading(false);
        }
      });
    },
    [url]
  );

  const handlePagination = (e: any, numresults: number) => {
    if (_isMounted.current) setIsLoading(true);
    client(url, {
      method: "GET",
      params: { q: query, page: page + 1 },
    }).then((resp) => {
      const options = resp.results;
      if (_isMounted.current) {
        setPage((oldPage) => oldPage + 1);
        setPaginate(!!resp.next);
        setOptions((oldOptions) => oldOptions.concat(options));
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (defaultValue) {
      client(`${url}${defaultValue}/`, {
        method: "GET",
      }).then((resp) => {
        if (_isMounted.current) setOptions([resp]);
      });
    }
  }, [defaultValue, url]);

  return (
    <AsyncTypeahead
      positionFixed={true}
      clearButton
      inputProps={{
        required: required,
        style: { backgroundColor: "transparent" }
      }}
      id={name.toString()}
      paginate={paginate}
      maxResults={PAGE_SIZE - 1}
      useCache={false}
      isLoading={isLoading}
      onInputChange={(input, e) => {
        setQuery(input);
      }}
      filterBy={() => true}
      onPaginate={handlePagination}
      onSearch={handleSearch}
      onChange={(data: any) => {
        if (data.length > 0) {
          onChange(data[0].id);
        } else {
          onChange(null);
        }
      }}
      options={options}
      minLength={1}
      disabled={disabled}
      selected={options.filter((option: any) => option.id === defaultValue)}
      placeholder={placeholder}

    />
  );
};
