import { FilterI } from "components/Filter/types";
import { OrderFilter } from "Traction/types";

export const createFilterStructure = (params: OrderFilter): FilterI[] => {
  return [
    {
      type: "date",
      name: "created_date_after",
      label: "Creato dal",
      active: params?.created_date_after !== undefined,
      value: params?.created_date_after,
    },

    {
      type: "date",
      name: "created_date_before",
      label: "Creato al",
      active: params?.created_date_before !== undefined,
      value: params?.created_date_before,
    },
    {
      type: "date",
      name: "date_after",
      label: "Dal",
      active: params?.date_after !== undefined,
      value: params?.date_after,
    },

    {
      type: "date",
      name: "date_before",
      label: "Al",
      active: params?.date_before !== undefined,
      value: params?.date_before,
    },
    {
      type: "number",
      name: "id",
      label: "ID",
      active: params?.id !== undefined,
      value: params?.id,
    },
    {
      type: "autocomplete-client",
      name: "client",
      label: "Cliente",
      active: params?.client !== undefined,
      value: params?.client,
    },
    {
      type: "boolean",
      name: "is_invoiced",
      label: "Fatturato?",
      active: params?.is_invoiced !== undefined,
      value: params?.is_invoiced,
    }
  ];
};
