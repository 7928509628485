import { FullModal } from "components/FullModal";
import React from "react";
import { useFilter } from "shared/hooks/useFilter";
import { useModalUpdate } from "shared/hooks/useModalUpdate";
import { useToggler } from "shared/hooks/useToggler";
import { AddOrderForm } from "Traction/components/AddOrderForm";
import { OrderListPage } from "Traction/components/OrderListPage";

import { UpdateOrderForm } from "Traction/components/UpdateOrderForm";
import { useDestinyTractionColumns } from "Traction/hooks/destinyTraction";
import {
  useAddOrder,
  useDeleteOrder,
  useOptionsOrder,
  useOrderColumns,
  useSearchOrder,
  useUpdateOrder,
} from "Traction/hooks/orders";
import { Order, OrderFilter } from "Traction/types";

export const Orders = () => {
  const { paramsObj, onSearch } = useFilter<OrderFilter>();
  const { value: isOpen, toggle, setTrue: openAdd } = useToggler();
  const { data: options } = useOptionsOrder();
  const {
    activeItem: activeOrder,
    setActiveItem: setActiveOrder,
    isOpenUpdateModal,
    toggleUpdateModal,
  } = useModalUpdate<Order>();
  const {
    data,
    search,
    goNextPage,
    goPrevPage,
    goToPage,
    canPrevPage,
    canNextPage,
    page,
    pageCount,
  } = useSearchOrder(paramsObj);

  const searchItems = (data: Partial<OrderFilter>) => {
    onSearch(data);
    search(data);
  };
  const { mutate: deleteOrder } = useDeleteOrder();
  const { mutateAsync: addOrder } = useAddOrder();
  const { mutateAsync: updateOrder } = useUpdateOrder();

  const columns = useOrderColumns(
    options
  );
  return (
    <>
      {data && data.results && options && (
        <OrderListPage
          deleteFunction={deleteOrder}
          columns={columns}
          orders={data.results}
          page={page}
          pageCount={pageCount}
          canNextPage={canNextPage}
          canPrevPage={canPrevPage}
          goToPage={goToPage}
          goNextPage={goNextPage}
          goPrevPage={goPrevPage}
          onSearch={searchItems}
          urlParamsObj={paramsObj}
          onAddClick={openAdd}
          setActiveOrder={setActiveOrder}
        />
      )}
      <FullModal toggle={toggle} isOpen={isOpen} title="Aggiungi ordine">
        <AddOrderForm submitFunction={addOrder} />
      </FullModal>
      {activeOrder && (
        <FullModal
          title="Modifica ordine"
          toggle={toggleUpdateModal}
          isOpen={isOpenUpdateModal}
        >
          <UpdateOrderForm order={activeOrder} submitFunction={updateOrder} />
        </FullModal>
      )}
    </>
  );
};
