import { set } from "lodash";
import React from "react";
import { Controller } from "react-hook-form";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { InputType } from "reactstrap/lib/Input";
import DatePicker, { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import classNames from "classnames";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import { url } from "inspector";
import { AutocompleteNoController } from "components/autocompletes/AutocompleteNoController";

interface Props {
  type: InputType | "autocomplete";
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  disabled?: boolean;
  hidden?: boolean;
  defaultValue?: any;
  required?: boolean;
  updateInline: any;
  id: number;
  value: any;
  key_name: string;
  url?: string;
  width?: string;
}

export const InputColumnCell = ({
  errorMessage,
  type,
  label,
  placeholder,
  disabled = false,
  hidden = false,
  required = false,
  defaultValue,
  updateInline,
  id,
  value,
  key_name,
  url,
  width = "150px"
}: Props) => {
  const [cellColor, setCellColor] = React.useState("white");
  const [newValue, setNewValue] = React.useState(value);


  function inputTypeSwitch(props: any) {
    switch (props.type) {
      case "datetime":
        return (
          <DatePicker
            className="trasparent-bg form-control"
            required={required}
            locale={it}
            disabled={disabled}
            dateFormat="dd/MM/yyyy HH:mm"
            showTimeInput={false}
            selected={value !== null && value ? new Date(value) : null}
            onChange={(e) => {
              let updateValues: any = {
              };
              updateValues[key_name] = e
              updateInline(
                {
                  id: id,
                  newValues: updateValues
                },
                {
                  onSuccess: () => {
                    setCellColor("rgb(0, 255, 0, 0.24)");
                  }
                },
                {
                  onError: () => {
                    setCellColor("rgb(255, 0, 0, 0.24)");
                  }
                }
              )
            }}
          />
        );
      case "date":
        return (
          <Input
            disabled={disabled}
            className="form-control"
            name={key_name}
            type={"date"}
            defaultValue={newValue}
            style={{ backgroundColor: "transparent" }}
            autoComplete="off"
            onChange={(e) => {
              let updateValues: any = {
              };

              updateValues[key_name] = e.currentTarget.value
              updateInline(
                {
                  id: id,
                  newValues: updateValues
                },
                {
                  onSuccess: () => {
                    setCellColor("rgb(0, 255, 0, 0.24)");
                  }
                },
                {
                  onError: () => {
                    setCellColor("rgb(255, 0, 0, 0.24)");
                  }
                }
              )

            }}
          />

        );
      case "autocomplete":
        return (
          url ?
            <AutocompleteNoController
              url={url}
              defaultValue={newValue
                ? newValue
                : null}
              name={key_name}
              disabled={disabled}
              onChange={(e: any) => {
                setNewValue(e);
                let updateValues: any = {
                };
                updateValues[key_name] = e
                updateInline(
                  {
                    id: id,
                    newValues: updateValues
                  },
                  {
                    onSuccess: () => {
                      setCellColor("rgb(0, 255, 0, 0.24)");
                    }
                  },
                  {
                    onError: () => {
                      setCellColor("rgb(255, 0, 0, 0.24)");
                    }
                  }
                )
              }}
            /> : <></>
        )
      case "time":
        return (
          <Input
            disabled={disabled}
            className="form-control"
            name={key_name}
            type={"time"}
            defaultValue={newValue}
            style={{ backgroundColor: "transparent" }}
            autoComplete="off"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                let updateValues: any = {
                };

                updateValues[key_name] = newValue
                updateInline(
                  {
                    id: id,
                    newValues: updateValues
                  },
                  {
                    onSuccess: () => {
                      setCellColor("rgb(0, 255, 0, 0.24)");
                    }
                  },
                  {
                    onError: () => {
                      setCellColor("rgb(255, 0, 0, 0.24)");
                    }
                  }
                )
              }
            }}
            onChange={(e) => {
              setNewValue(e.currentTarget.value);
              setCellColor("rgb(255, 255, 0, 0.24)");
            }}
          />
        );
      default:
        return (
          <Input
            disabled={disabled}
            className="form-control"
            name={key_name}
            type={type as InputType}
            defaultValue={newValue}
            style={{ backgroundColor: "transparent" }}
            autoComplete="off"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                let updateValues: any = {
                };

                updateValues[key_name] = newValue
                console.log(newValue)
                updateInline(
                  {
                    id: id,
                    newValues: updateValues
                  },
                  {
                    onSuccess: () => {
                      setCellColor("rgb(0, 255, 0, 0.24)");
                    }
                  },
                  {
                    onError: () => {
                      setCellColor("rgb(255, 0, 0, 0.24)");
                    }
                  }
                )
              }
            }}
            onChange={(e) => {
              setNewValue(e.currentTarget.value);
              setCellColor("rgb(255, 255, 0, 0.24)");
            }}
          />
        );
    }
  }

  return (
    <div id={id + "_" + key_name} style={{ minWidth: width, backgroundColor: cellColor }}>
      {
        inputTypeSwitch({ type })
      }

    </div>
  );
};
