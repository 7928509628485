import { useToggler } from "shared/hooks/useToggler";
import { AddDeliveryForm } from "../components/AddDeliveryForm";
import { Delivery, DeliveryFilter } from "../types/delivery";
import {
  useAddDelivery,
  useBackDeliveries,
  useConsignmentToTraction,
  useForceConsignmentStatus,
  useOptionsDelivery,
  useResendNotes,
  useResendNotesText,
  useSearchDeliveries,
} from "../hooks/delivery";
import { useFilter } from "shared/hooks/useFilter";
import { FullModal } from "components/FullModal";
import { DeliveryListPage } from "Distribution/components/DeliveryListPage";
import { client } from "utils/auth-client";
import { DELIVERIES_API } from "utils/constants";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { useGlobalModals } from "shared/context/globalModals";
import { useState } from "react";

export const Deliveries = () => {
  const { paramsObj, onSearch } = useFilter<DeliveryFilter>();
  const { data, search, page, goToPage, pageCount } =
    useSearchDeliveries(paramsObj);
  const { mutateAsync } = useAddDelivery();
  const { value: isOpen, toggle } = useToggler();
  const { data: options } = useOptionsDelivery();
  const queryClient = useQueryClient();
  const { setActiveItem } = useGlobalModals();
  const { mutateAsync: forceConsignmentStatus } = useForceConsignmentStatus();
  const { mutateAsync: consignmentToTraction } = useConsignmentToTraction();
  const { mutateAsync: backDeliveries } = useBackDeliveries();
  const { mutateAsync: resendNotes } = useResendNotes();
  const { mutateAsync: resendNotesText } = useResendNotesText();
  const [selectedRows, setSelectedRows] = useState<Delivery[]>([]);

  const searchItems = (data: Partial<DeliveryFilter>) => {
    onSearch(data);
    search(data);
  };

  const onCloneClick = (delivery: Delivery) => {
    client(`${DELIVERIES_API}${delivery.id}/clone_delivery/`, {
      method: "GET",
    })
      .then((data) => {
        toast.success("Creata missione " + data.id);
        setActiveItem("delivery", data.id);
        queryClient.refetchQueries(["deliveries"], { active: true });
      })
      .catch((error) => {
        toast.error(error.response.data.message, { autoClose: 3000 });
      });
    //setClonedDelivery({ ...delivery, route_id: null, bill_sent: null });
    //toggle();
  };

  return (
    <>
      {data && options && (
        <DeliveryListPage
          consignmentToTraction={consignmentToTraction}
          backDeliveries={backDeliveries}
          resendNotes={resendNotes}
          resendNotesText={resendNotesText}
          forceConsignmentStatus={forceConsignmentStatus}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          total={data.count}
          with_note_no_final={data.with_note_no_final}
          with_note_final={data.with_note_final}
          deliveries={data.results}
          urlParamsObj={paramsObj}
          onSearch={searchItems}
          onAddClick={toggle}
          page={page}
          pageCount={pageCount}
          goToPage={goToPage}
          options={options}
          cloneDelivery={onCloneClick}
        />
      )}
      <FullModal title="Aggiungi missione" toggle={toggle} isOpen={isOpen}>
        <AddDeliveryForm submitFunction={mutateAsync} />
      </FullModal>
    </>
  );
};
