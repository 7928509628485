export const url = `${window.location.protocol}//${window.location.hostname}:5000/api/`;
export const TOKEN_API = `${url}token/`;
export const TOKEN_REFRESH_API = `${url}token/refresh/`;
export const DRIVERS_API = `${url}drivers/`;
export const SUBJECTS_API = `${url}subjects/`;
export const CLIENTS_API = `${url}clients/`;
export const VEHICLES_API = `${url}vehicles/`;
export const ITEMS_API = `${url}items/`;
export const PRICES_API = `${url}prices/`;
export const DELIVERIES_API = `${url}deliveries/`;
export const PACKAGES_API = `${url}packages/`;
export const DELIVERY_NOTE_ROWS_API = `${url}delivery-note-rows/`;
export const PRICES_CLIENT_API = `${url}prices-client/`;
export const DELIVERIES_PLANNER_API = `${url}deliveries-plan/`;
export const CREATE_ROUTE_API = `${url}create-route`;
export const AUTO_ORDER_API = `${url}deliveries-plan/plan/`;
export const ROUTES_API = `${url}routes/`;
export const UPLOAD_API = `${url}upload/`;
export const EXECUTE_IMPORT_API = `${url}execute-upload/`;
export const MISSING_SUBJECTS_API = `${url}solve-subjects/`;
export const ASSIGN_MISSING_SUBJECT_API = `${url}assign-subjects/`;
export const IMPORTED_FILES_API = `${url}imported-files/`;
export const TERMS_PAYMENT_API = `${url}payment-terms/`;
export const TERMS_DESCRIPTION_API = `${url}terms-description/`;
export const CHARGES_API = `${url}charges/`;
export const INVOICES_API = `${url}invoices/`;
export const ORDERS_API = `${url}orders/`;
export const SEGMENTS_API = `${url}segments/`;
export const ORDER_POINTS_API = `${url}orderpoints/`;
export const DESTINY_TRACTION_API = `${url}destinies-traction/`;
export const DESTINY_TRACTION_PALLET_API = `${url}destinies-traction-pallet/`;
export const TRAILERS_API = `${url}trailers/`;
export const NOTES_API = `${url}notes/`;
export const FUEL_CONSUMPTION_FILES_API = `${url}fuel-consumption-files/`;
export const FUEL_SITE_API = `${url}fuel-sites/`;
export const FUEL_CONSUMPTION_API = `${url}fuel-consumption/`;
export const SPECIFICATION_NOTE_API = `${url}specification-note/`;
export const PALLETS_API = `${url}pallets/`;
export const SECTOR_API = `${url}sectors/`;
export const ROW_API = `${url}rows/`;
export const FLOOR_API = `${url}floors/`;
export const PLACE_API = `${url}places/`;
export const PRODUCT_TYPE_API = `${url}product_types/`;
export const PRODUCT_API = `${url}products/`;
export const ARTICLE_API = `${url}articles/`;
export const STATUS_API = `${url}status/`;
export const RESOLVE_QRCODE_API = `${url}resolve-qr-codes/`;

export const AUTOCOMPLETE_URLS = {
  SUBJECT: `${url}autocomplete/subject/`,
  CLIENT: `${url}autocomplete/client/`,
  DRIVER: `${url}autocomplete/driver/`,
  VEHICLE: `${url}autocomplete/vehicle/`,
  ITEM: `${url}autocomplete/item/`,
  ROUTE: `${url}autocomplete/route/`,
  DELIVERY: `${url}autocomplete/delivery/`,
  INOVICE: `${url}autocomplete/invoice/`,
  PRICE: `${url}autocomplete/price/`,
  TRAILER: `${url}autocomplete/trailer/`,
  TERMS_DESCRIPTION: `${url}autocomplete/terms-description/`,
  NOTES: `${url}autocomplete/notes/`,
  FUEL_SITE: `${url}autocomplete/fuel-sites/`,
  FUEL_CONSUMPTION_FILE: `${url}autocomplete/fuel-consumption-files/`,
  PALLET: `${url}autocomplete/pallets/`,
  IMPORTED_FILES: `${url}autocomplete/imported-files/`,
  PROVINCES: `${url}autocomplete/provinces/`,
  ORDERS: `${url}autocomplete/orders/`,
  SECTOR: `${url}autocomplete/sectors/`,
  ROW: `${url}autocomplete/rows/`,
  FLOOR: `${url}autocomplete/floors/`,
  PLACE: `${url}autocomplete/places/`,
  PRODUCT_TYPE: `${url}autocomplete/product_types/`,
  PRODUCT: `${url}autocomplete/products/`,
  ARTICLE: `${url}autocomplete/articles/`,
};
export const PAGE_SIZE = 500;

export const GROUPS = {
  ADMIN: "admin",
  CLIENT: "client",
  PLANNER: "planner",
  ACCOUNTANT: "accountant",
  DRIVER: "driver",
};

export const MESSAGES = {
  GENERAL: {
    NEW_ITEM: "Aggiungi",
    NO_ITEMS: "Nessun risultato trovato!",
    UPDATE_ITEM: "Modifica",
    SEND_FORM: "Invia",
    ADD_SUCCESS: "Fatto!",
    UPDATE_SUCCESS: "Fatto!",
  },
  FUEL_CONSUMPTION_FILE: {
    NO_ITEMS: "Nessun file trovato",
    ADD_SUCCESS: "File caricato!",
  },
  FUEL_SITE: {
    MODAL_NEW: "Aggiungi fornitore",
    MODAL_UPDATE: "Modifica fornitore",
    ADD_SUCCESS: "Fornitore aggiunto",
  },
  FUEL_CONSUMPTION: {
    MODAL_NEW: "Aggiungi consumo",
    MODAL_UPDATE: "Modifica consumo",
    ADD_SUCCESS: "Consumo aggiunto",
    UPDATE_SUCCESS: "Consumo modificato",
  },
  PALLET: {
    MODAL_NEW: "Aggiungi tipologia pallet",
    MODAL_UPDATE: "Modifica tipologia pallet",
    ADD_SUCCESS: "Tipologia pallet aggiunta",
    UPDATE_SUCCESS: "Tipologia pallet modificata",
  },
  PACKAGES: {
    EDIT_MESSAGE:
      "Modificare gli articoli modificherà gli addebiti. Vuoi continuare?",
  },
};

export const STORE_POSITION = {
  latitude: 45.1855270066249,
  longitude: 11.579935884552341,
};
