import { UseQueryOptions } from "react-query";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { ORDERS_API } from "utils/constants";
import { Order } from "Traction/types";
import { useGlobalModals } from "shared/context/globalModals";
import { useMemo } from "react";
import { Button } from "reactstrap";
import { filterJson } from "components/tables/filters";
import { getDisplayDate, getDisplayValue } from "utils/helpers";
import { DeleteButton } from "components/buttons/DeleteButton";
import { getSelectableColumn } from "components/tables/selectableColumn";
import { EditButton } from "components/buttons/EditButton";

const key = "orders";
const url = ORDERS_API;

export function useOptionsOrder() {
  return useOptions(url, key);
}

export function useAddOrder() {
  return useAdd<Order>(url, key);
}

export function useDeleteOrder() {
  return useDelete(url, key);
}

export function useUpdateOrder() {
  return useUpdate<Order>(url, key);
}

export function useListOrder(
  params?: any,
  config?: UseQueryOptions<any, unknown, Order[]>
) {
  return useList(url, key, params, config);
}

export function useSearchOrder(searchParams?: any) {
  return useSearchPaginated<Order>(url, key, searchParams);
}

export function useOrder(
  id: string | number,
  config?: UseQueryOptions<any, unknown, Order>
) {
  return useGetItem<Order>(id, url, key, config);
}


export function useOrderColumns(
  options: any
) {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteOrder } = useDeleteOrder();

  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteOrder(id);
    };
    return options ? [
      getSelectableColumn(),
      {
        accessor: "__id",
        Header: "Modifica",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <div className="d-flex flex-row justify-content-start">

              <DeleteButton
                permissions={["rosina.change_delivery"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              <EditButton
                onClick={() => setActiveItem("order", cell.row.original.id)}
                permissions={[]}
              />
            </div>
          );
        },
      },
      {
        accessor: "id",
        Header: "ID",
        sortType: "basic",
      },
      {
        Header: "Cliente",
        accessor: "client",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.client)
            return (
              <Button
                title={"Mostra soggetto"}
                color="link"
                onClick={() =>
                  setActiveItem("subject", cell.row.original.client.id)
                }
              >{`${cell.row.original.client.id}: ${cell.row.original.client.business_name}`}</Button>
            );
          return "";
        },
        filter: filterJson
      },
      {
        accessor: "order_number",
        Header: "N. ordine",
        sortType: "basic",
      },
      {
        accessor: "date",
        Header: "Data carico",
        sortType: "basic",
        Cell: (cell: any) => getDisplayDate(cell.value),
      },
      {
        accessor: "required_transport",
        Header: "Tipo",
        sortType: "basic",
        Cell: (cell: any) => getDisplayValue("required_transport", cell.value, options),
      },
      {
        accessor: "value",
        Header: "€",
        sortType: "basic",
      },
      {
        Header: "Carico",
        accessor: "orderpoints",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.orderpoints && cell.row.original.orderpoints.length > 0)
            return (
              <><b>{`${cell.row.original.orderpoints[0].province}`}</b>{`: ${cell.row.original.orderpoints[0].place}`}</>
            );
          return "";
        },
        filter: filterJson
      },
      {
        Header: "1° Scarico",
        accessor: "orderpoints2",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.orderpoints && cell.row.original.orderpoints.length > 1)
            return (
              <><b>{`${cell.row.original.orderpoints[1].province}`}</b> {`: ${cell.row.original.orderpoints[1].place}`} </>
            );
          return "";
        },
        filter: filterJson
      },
      {
        Header: "Fatturato",
        accessor: "invoice_status",
        sortType: "basic",

      }
    ] : options;
  }, [setActiveItem, options]);
}
