import { Delivery, DeliveryFilter } from "Distribution/types/delivery";
import { Filter } from "components/Filter";
import { useState } from "react";
import { Alert, Button, Card, CardBody, Input } from "reactstrap";
import { createFilterStructure } from "./filter";
import { File, X } from "react-feather";
import { Table } from "components/tables/Table";
import { useDeliveryColumns } from "Distribution/hooks/delivery";
import classNames from "classnames";
import { PackagesCard } from "../PackagesCard";
import { CheckPermissions } from "components/CheckPermissions";
import { StatsCard } from "components/StatsCard";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";


interface Props {
  urlParamsObj: {
    [k: string]: unknown;
  };
  onSearch: (data: Partial<DeliveryFilter>) => void;
  onAddClick: () => void;
  deliveries: Delivery[];
  page: number;
  pageCount: number;
  goToPage: (page: number) => void;
  cloneDelivery: (delivery: Delivery) => void;
  options: any;
  total: number;
  with_note_no_final: number;
  with_note_final: number;
  setSelectedRows: (delivery: Delivery[]) => void;
  selectedRows: Delivery[];
  forceConsignmentStatus: UseMutateAsyncFunction<
    any,
    unknown,
    {
      ids: number[];
    },
    unknown
  >;
  consignmentToTraction: UseMutateAsyncFunction<
    any,
    unknown,
    {
      ids: number[];
    },
    unknown
  >;
  backDeliveries: UseMutateAsyncFunction<
    any,
    unknown,
    {
      ids: number[];
    },
    unknown
  >;
  resendNotes: UseMutateAsyncFunction<
    any,
    unknown,
    {
      ids: number[];
    },
    unknown
  >;
  resendNotesText: UseMutateAsyncFunction<
    any,
    unknown,
    {
      text: String;
    },
    unknown
  >;
}

export const DeliveryListPage = ({
  urlParamsObj,
  onSearch,
  onAddClick,
  deliveries,
  page,
  pageCount,
  goToPage,
  options,
  total,
  cloneDelivery,
  with_note_no_final,
  with_note_final,
  setSelectedRows,
  selectedRows,
  forceConsignmentStatus,
  consignmentToTraction,
  backDeliveries,
  resendNotes,
  resendNotesText,
}: Props) => {
  const [showPackages, setShowPackages] =
    useState<undefined | number>(undefined);

  const columns = useDeliveryColumns(setShowPackages, cloneDelivery, options);

  return (
    <>
      <div className="d-flex flex-row justify-content-between my-3">
        <Filter
          paramsObj={urlParamsObj}
          onFilterChange={onSearch}
          filterStructure={createFilterStructure(urlParamsObj, options)}
        />

        <CheckPermissions permissions={["rosina.change_delivery"]}>
          <Button
            disabled={selectedRows.length <= 0}
            onClick={() =>
              consignmentToTraction({
                ids: selectedRows.map((delivery) => delivery.id!),
              }).then((result: any) => {
                if (result.done === true) {
                  toast.success("Fatto!");
                } else {
                  toast.error("Errore!");
                }
              })
            }
            size="sm"
            color="primary"
            className="ml-2"
          >
            Converti in dirette
          </Button>
        </CheckPermissions>
        <CheckPermissions permissions={["rosina.change_delivery"]}>
          <Button
            disabled={selectedRows.length <= 0}
            onClick={() =>
              backDeliveries({
                ids: selectedRows.map((delivery) => delivery.id!),
              }).then((result: any) => {
                if (result.done === true) {
                  toast.success("Fatto!");
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                } else {
                  toast.error("Errore!");
                }
              })
            }
            size="sm"
            color="primary"
            className="ml-2"
          >
            Crea riconsegne
          </Button>
        </CheckPermissions>
        <CheckPermissions permissions={["rosina.change_delivery"]}>
          <Button
            disabled={selectedRows.length <= 0}
            onClick={() =>
              resendNotes({
                ids: selectedRows.map((delivery) => delivery.id!),
              }).then((result: any) => {
                if (result.done === true) {
                  toast.success("Fatto!");
                } else {
                  toast.error("Errore!");
                }
              })
            }
            size="sm"
            color="primary"
            className="ml-2"
          >
            Rinvia bolle
          </Button>
        </CheckPermissions>
        <CheckPermissions permissions={["rosina.change_delivery"]}>
          <Button
            onClick={() =>
              resendNotesText({
                text: window.prompt("Inserisci i numeri documento da rinviare") || "",
              }).then((result: any) => {
                if (result.done === true) {
                  toast.success("Fatto!");
                } else {
                  toast.error("Errore!");
                }
              })
            }
            size="sm"
            color="primary"
            className="ml-2"
          >
            Rinvia lista bolle
          </Button>
        </CheckPermissions>

        <CheckPermissions permissions={["rosina.change_delivery"]}>
          <Button onClick={() => onAddClick()} size="sm" color="primary">
            Aggiungi
          </Button>
        </CheckPermissions>
        <CheckPermissions permissions={["rosina.change_delivery"]}>
          <Button
            disabled={selectedRows.length <= 0}
            onClick={() =>
              forceConsignmentStatus({
                ids: selectedRows.map((delivery) => delivery.id!),
              }).then((result: any) => {
                if (result.done === true) {
                  toast.success("Fatto!");
                } else {
                  toast.error("Errore!");
                }
              })
            }
            size="sm"
            color="primary"
            className="ml-2"
          >
            Forza in consegna
          </Button>
        </CheckPermissions>
      </div>
      <div className="row my-3">
        <div className="col-4">
          <StatsCard
            number={`${total}`}
            icon={File}
            title={"Missioni totali"}
          />
        </div>
        <div className="col-4">
          <StatsCard
            number={`${with_note_final}`}
            icon={File}
            title={"Bolle finali"}
            color="green"
          />
        </div>
        <div className="col-4">
          <StatsCard
            number={`${with_note_no_final}`}
            icon={File}
            title={"Bolle iniziali"}
            color="orange"
          />
        </div>
      </div>
      <div className="row ">
        <div
          className={classNames("col-12", {
            "col-lg-8 col-md-6": showPackages,
          })}
        >
          {deliveries.length === 0 ? (
            <Alert color="primary">Nessuna missione trovata </Alert>
          ) : (
            <>
              <Input type="text" placeholder="Cerca..."
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSearch({ global_search: e.currentTarget.value })
                  }
                }}
              />
              <Card>
                <CardBody className="p-1">
                  <Table
                    setSelectedRows={setSelectedRows}
                    pageIndex={page}
                    pageCount={pageCount}
                    setPage={(page: number) => goToPage(page)}
                    data={deliveries}
                    columns={columns}
                    sortBy="id"
                  />
                </CardBody>
              </Card>
            </>
          )}
        </div>
        {showPackages && (
          <div className="col-12 col-lg-4 col-md-6 animate__animated animate__fadeInRight animate__faster">
            <div className="d-flex flex-column">
              {showPackages && (
                <>
                  <div className="d-flex flex-row justify-content-end my-2 align-items-center">
                    <Button
                      style={{
                        color: "red",
                        background: "white",
                        border: "none",
                      }}
                      className="edit-buttons"
                      onClick={() => setShowPackages(undefined)}
                    >
                      <X size="20" />
                    </Button>
                  </div>
                  <PackagesCard id_delivery={showPackages} />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
