import { Button } from "reactstrap";
import { CornerDownLeft, CornerDownRight, Plus, Trash, X } from "react-feather";
import { deliveriesNoWithdrawals, deliveryContainsWDirect, deliveryContainsWithdrawals } from "../utils";
import { Rnd } from "react-rnd";
import React, { useMemo } from "react";
import { DeliveryPlan } from "../types";
import { Route } from "../../Distribution/types/route";
import { Delivery } from "Distribution/types/delivery";
import { SimpleTable } from "components/tables/SimpleTable";
import { Link } from "react-router-dom";
import { useGlobalModals } from "shared/context/globalModals";

interface Props {
  deliveries: Delivery[];
  addedDeliveries: Delivery[];
  resetActiveClusterKey: () => void;
  //   updateOnAllDeliveries: (key: string, action: "add" | "remove") => void;
  addDeliveries: (key: string, hideWithdrawals: boolean) => void;
  deleteDeliveries: (key: string) => void;
  //   toggleSelectedDeliveries: (delivery: DeliveryPlan) => void;
  onAddClick: (segment: Delivery) => void;

  selectedRoute: undefined | Route;
  hideWithdrawals: boolean;
  activeClusterKey?: string;
}

function calculateSubTotal(deliveries: DeliveryPlan[]) {
  const defaultValue = { weight: 0, number: 0, packages_number: 0 };

  return deliveries.reduce((result, delivery) => {
    let containsRI = deliveryContainsWithdrawals(delivery);

    if (!containsRI && delivery.packages_number)
      result.packages_number += delivery.packages_number;
    if (!containsRI && delivery.weight) result.weight += delivery.weight;
    result.number += 1;
    return result;
  }, defaultValue);
}

export const PopupTable = React.forwardRef<Rnd, Props>((props, ref) => {
  const filteredDeliveries = props.hideWithdrawals
    ? deliveriesNoWithdrawals(props.deliveries as DeliveryPlan[])
    : props.deliveries;
  const statistics = calculateSubTotal(filteredDeliveries as DeliveryPlan[]);
  const { setActiveItem } = useGlobalModals();
  const columns = useMemo(() => {
    return [
      {
        accessor: "__id",
        Header: "",
        sortType: "basic",
        Cell: (cell: any) => {
          const added = props.addedDeliveries.find(
            (segment) => segment.id === cell.row.original.id
          );
          return (
            <div className="d-flex flex-row justify-content-between">
              {!cell.row.original.route_id ||
                (props.selectedRoute &&
                  cell.row.original.route_id === props.selectedRoute.id) ? (
                <Button
                  title={"Elimina"}
                  onClick={() => {
                    props.onAddClick(cell.row.original);
                  }}
                  className="edit-buttons"
                  color={added ? "danger" : "success"}
                >
                  {added ? <Trash size={13} /> : <Plus size={13} />}
                </Button>
              ) : null}
            </div>
          );
        },
      },
      {
        Header: "ID",
        accessor: "id",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.id)
            return (
              <Button
                color="link"
                onClick={() => setActiveItem("delivery", cell.row.original.id)}
              >
                {`${cell.row.original.id}`}
              </Button>
            );

          return "";
        },
      },
      {
        Header: "Destinatario",
        accessor: "receiver",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.receiver)
            return `${cell.row.original.receiver.id}: ${cell.row.original.receiver.business_name}`;
          return "";
        },
      },
      {
        Header: "Cliente",
        accessor: "client",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.client)
            return `${cell.row.original.client.id}: ${cell.row.original.client.business_name}`;
          return "";
        },
      },
      {
        Header: "Sub",
        accessor: "sub_client",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.sub_client)
            return `${cell.row.original.sub_client.substring(0, 3)}`;
          return "";
        },
      },
      {
        Header: "Peso",
        accessor: "weight",
        sortType: "basic",
      },
      {
        Header: "N. colli",
        accessor: "packages_number",
        sortType: "basic",
      },
      {
        Header: "Giro",
        accessor: "route_id",
        sortType: "basic",
      },
      {
        Header: "Rit/Dir",
        accessor: "",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <>
              {
                deliveryContainsWithdrawals(cell.row.original) ?
                  (<CornerDownLeft color={"red"} size={20} />) :
                  (
                    deliveryContainsWDirect(cell.row.original) ?
                      <CornerDownRight color={"green"} size={20} /> : ""
                  )
              }
            </>
          );
        },
      },
      {
        Header: "Note",
        accessor: "all_notes",
        sortType: "basic",

      }
    ];
  }, [props]);
  return (
    <Rnd
      ref={ref}
      enableResizing={false}
      maxWidth={"710px"}
      className={props.activeClusterKey ? "card__deliveries shadow" : "d-none"}
      default={{ x: 0, y: 0, width: "auto", height: "auto" }}
    >
      {props.deliveries.length > 0 && (
        <div className="py-2 px-1">
          <div className="d-flex flex-row justify-content-between mb-2">
            <div className="d-flex flex-row justify-content-start">
              {props.activeClusterKey && (
                <>
                  <Button
                    color="success"
                    outline
                    size="sm"
                    className="edit-buttons"
                    onClick={() =>
                      props.addDeliveries(
                        props.activeClusterKey!,
                        props.hideWithdrawals
                      )
                    }
                  >
                    Aggiungi tutto
                  </Button>
                  <Button
                    color="danger"
                    outline
                    size="sm"
                    className="edit-buttons"
                    onClick={() =>
                      props.deleteDeliveries(props.activeClusterKey!)
                    }
                  >
                    Rimuovi tutto
                  </Button>
                </>
              )}
            </div>
            <div className="d-flex flex-row justify-content-end">
              <Button
                style={{
                  color: "red",
                  background: "white",
                  border: "none",
                }}
                className="edit-buttons"
                onClick={() => props.resetActiveClusterKey()}
              >
                <X size="20" />
              </Button>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between px-2">
            <span>
              <b>N.missioni:</b> {statistics.number}
            </span>
            <span>
              <b>Peso:</b> {statistics.weight}
            </span>
            <span>
              <b>Colli:</b> {statistics.packages_number}
            </span>
          </div>
          <div className="d-flex">
            <SimpleTable
              style={{ maxHeight: "400px" }}
              columns={columns}
              data={filteredDeliveries}
            />
          </div>
        </div>
      )}
    </Rnd>
  );
});
