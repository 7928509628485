import { Delivery } from "Distribution/types/delivery";
import { DeliveryGroup, DeliveryPlan } from "./types";

export function deliveryContainsWithdrawals(delivery: DeliveryPlan | Delivery) {
  return delivery.packages!.some(function (e) {
    return e.price?.charge_type === "RI";
  });
}

export function deliveryContainsWDirect(delivery: DeliveryPlan | Delivery) {
  console.log(delivery)
  return delivery.type === "DI"
}

export function deliveriesContainsOnlyWithdrawals(deliveries: DeliveryPlan[]) {
  return deliveries.every(function (delivery: DeliveryPlan) {
    return delivery.packages!.some(function (e) {
      return e.price?.charge_type === "RI";
    });
  });
}


export function deliveriesNotContainsOnlyWithdrawals(deliveries: DeliveryPlan[]) {
  return deliveries.every(function (delivery: DeliveryPlan) {
    return delivery.packages!.every(function (e) {
      return e.price?.charge_type !== "RI";
    });
  });
}

export function deliveriesNoWithdrawals(deliveries: DeliveryPlan[]) {
  return deliveries.filter(function (delivery: DeliveryPlan) {
    return delivery.packages!.every(function (e) {
      return e.price?.charge_type !== "RI";
    });
  });
}

export function deliveryContainsNotes(deliveries: DeliveryPlan[] | Delivery[]) {
  return deliveries.some(function (delivery) {
    return delivery.all_notes?.split(" ").join("") !== "";
  });
}

export function getDeliveriesGrouped(addedDeliveries: Delivery[]) {
  let tableData: DeliveryGroup[] = [];
  for (const delivery of addedDeliveries) {
    const id = `${delivery.receiver?.latitude},${delivery.receiver?.longitude}`;

    const groupRowIndex = tableData.findIndex((groupRow) => groupRow.id === id);
    let containsRI = deliveryContainsWithdrawals(delivery);
    if (groupRowIndex !== -1) {
      const groupRow = { ...tableData[groupRowIndex] };
      groupRow.weight! += containsRI ? 0 : delivery.weight!;
      groupRow.packages_number! += containsRI ? 0 : delivery.packages_number!;
      groupRow.sequence =
        delivery.sequence &&
          delivery.route_id &&
          delivery.sequence > groupRow.sequence
          ? delivery.sequence
          : groupRow.sequence;
      tableData.splice(groupRowIndex, 1, groupRow);
    } else {
      let newSequence = delivery.sequence ? delivery.sequence : 0;
      if (newSequence === 0 && !delivery.route_id) {
        newSequence =
          tableData.length > 0
            ? tableData[tableData.length - 1].sequence + 1
            : 0;
      }
      tableData.push({
        id: id,
        receiver: `${delivery.receiver!.id}: ${delivery.receiver!.business_name
          }`,
        weight: containsRI ? 0 : delivery.weight ? delivery.weight : 0,
        packages_number: containsRI
          ? 0
          : delivery.packages_number
            ? delivery.packages_number
            : 0,
        address: `${delivery.receiver!.address} ${delivery.receiver!.locality
          }, ${delivery.receiver!.postal_code} ${delivery.receiver!.province}`,
        longitude: delivery.receiver!.longitude,
        latitude: delivery.receiver!.latitude,
        sequence: newSequence,
      });
    }
  }
  tableData.sort((a, b) => {
    if (a.sequence < b.sequence) {
      return -1;
    }
    if (a.sequence > b.sequence) {
      return 1;
    }
    return 0;
  });
  return tableData;
}

export function getAddedDeliveries(
  groupedDeliveries: DeliveryGroup[],
  addedDeliveries: Delivery[]
) {
  const deliveries: Delivery[] = [];
  groupedDeliveries.forEach((group, index) => {
    addedDeliveries.forEach((delivery) => {
      const key = `${delivery.receiver!.latitude},${delivery.receiver!.longitude
        }`;

      if (key === group.id) {
        deliveries.push({ ...delivery, sequence: index + 1 });
      }
    });
  });
  return deliveries;
}
