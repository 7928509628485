import { useFilter } from "shared/hooks/useFilter";
import { OrderPointListPage } from "Traction/components/OrderPointListaPage";
import { useOptionsOrderPoint, useSearchOrderPoint } from "Traction/hooks/orderPoints";

export const OrderPoints = () => {
  const { paramsObj, onSearch } = useFilter<{ order: number }>();
  const { data, search, page, goToPage, pageCount } =
    useSearchOrderPoint(paramsObj);

  const searchItems = (data: Partial<{ order: number }>) => {
    onSearch(data);
    window.location.reload();
    //search(data);
  };
  const { data: options } = useOptionsOrderPoint();
  return (
    <>
      {data && data.results && options && (
        <OrderPointListPage
          options={options}
          orderpoints={data.results}
          urlParamsObj={paramsObj}
          onSearch={searchItems}
          page={page}
          pageCount={pageCount}
          goToPage={goToPage}
        />
      )}
    </>
  );
};
