import { CheckPermissions } from "components/CheckPermissions";
import { CustomPagination } from "components/CustomPagination";
import { Filter } from "components/Filter";
import React from "react";
import { UseMutateFunction } from "react-query";
import { Column } from "react-table";
import { Alert, Button, Card, CardBody } from "reactstrap";
import { Table } from "components/tables/Table";
import { Order, OrderFilter } from "Traction/types";
import { createFilterStructure } from "./filter";
import classNames from "classnames";

interface Props {
  urlParamsObj: {
    [k: string]: unknown;
  };
  onSearch: (data: Partial<OrderFilter>) => void;
  onAddClick: () => void;
  orders: Order[];
  columns: Column[];
  canPrevPage: () => boolean;
  goPrevPage: () => void;
  page: number;
  pageCount: number;
  goToPage: (page: number) => void;
  canNextPage: () => boolean;
  goNextPage: () => void;
  setActiveOrder: (order: Order) => void;
  deleteFunction: UseMutateFunction<any, unknown, number, unknown>;
}

export const OrderListPage = ({
  urlParamsObj,
  onSearch,
  onAddClick,
  orders,
  columns,
  canPrevPage,
  goPrevPage,
  page,
  pageCount,
  goToPage,
  canNextPage,
  goNextPage,
  setActiveOrder,
  deleteFunction,
}: Props) => {

  return (
    <>
      <div className="d-flex flex-row justify-content-between my-3">
        <Filter
          paramsObj={urlParamsObj}
          onFilterChange={onSearch}
          filterStructure={createFilterStructure(urlParamsObj)}
        />
        <CheckPermissions permissions={["rosina.change_order"]}>
          <Button onClick={() => onAddClick()} size="sm" color="primary">
            Aggiungi
          </Button>
        </CheckPermissions>
      </div>
      <div className="row ">
        <div className={classNames("col-12")}>
          {orders.length === 0 ? (
            <Alert color="primary">Nessun ordine trovato </Alert>
          ) : (
            <Card>
              <CardBody className="p-1">
                <Table
                  //setSelectedRows={setSelectedRows}
                  pageIndex={page}
                  pageCount={pageCount}
                  setPage={(page: number) => goToPage(page)}
                  data={orders}
                  columns={columns}
                  sortBy="id"
                />
              </CardBody>
            </Card>
          )}
        </div>

      </div>
    </>
  );
};
