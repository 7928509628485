import { FilterI } from "components/Filter/types";

export const createFilterStructure = (params: any): FilterI[] => {
  return [
    {
      type: "autocomplete-order",
      name: "order",
      label: "Ordine",
      active: params?.order !== undefined,
      value: params?.order,
    },
    {
      type: "autocomplete-driver",
      name: "driver",
      label: "Autista",
      active: params?.driver !== undefined,
      value: params?.driver,
    },
    {
      type: "boolean",
      name: "has_driver",
      label: "Assegnata all'autista",
      active: params?.no_driver !== undefined,
      value: params?.no_driver,
    },
    {
      type: "date",
      name: "date_after",
      label: "Data carico/scarico dal",
      active: params?.order__date_after !== undefined,
      value: params?.order__date_after,
    },

    {
      type: "date",
      name: "date_before",
      label: "Data carico/scarico al",
      active: params?.order__date_before !== undefined,
      value: params?.order__date_before,
    },
  ];
};
