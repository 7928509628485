import classNames from "classnames";
import { FormField } from "components/forms/FormField";
import { Tabs } from "components/Tabs";
import { BooleanField } from "components/forms/BooleanField";
import { ArrayField } from "react-hook-form";
import { Button, ListGroup, ListGroupItem } from "reactstrap";
import { SelectField } from "components/forms/SelectField";
import { DateTimeField } from "components/forms/DateTimeField";
import { AUTOCOMPLETE_URLS } from "utils/constants";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import React from "react";
import { CheckPermissions } from "components/CheckPermissions";
import { useOptionsOrder } from "Traction/hooks/orders";

interface Props {
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
  getValues: any;
  fields: Partial<ArrayField<Record<string, any>, "id">>[];
  remove: (index?: number | number[] | undefined) => void;
  append: (
    value: Partial<Record<string, any>> | Partial<Record<string, any>>[],
    shouldFocus?: boolean | undefined
  ) => void;
  options: any;
  watch: any;
}

export const OrderForm = ({
  control,
  register,
  errors,
  defaultValues,
  editable = true,
  getValues,
  fields,
  append,
  remove,
  options,
  watch,
}: Props) => {
  const { data: optionsOrder } = useOptionsOrder();

  return optionsOrder ? (
    <>
      <div className="d-flex flex-column flex-lg-row">
        <table className={classNames("table_col")}>
          <tbody>
            <tr>
              <td>
                <b>Cliente</b>
              </td>
              <td>
                <Autocomplete
                  disabled={!editable}
                  url={AUTOCOMPLETE_URLS.CLIENT}
                  control={control}
                  defaultValue={
                    defaultValues?.client_id ? defaultValues?.client_id : null
                  }
                  errorMessage={errors.client_id?.message}
                  name="client_id"
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>Data carico</b>
              </td>
              <td>
                <DateTimeField
                  defaultValue={defaultValues?.date ? defaultValues?.date : null}
                  disabled={true}
                  name={"date"}
                  errorMessage={errors.date?.message}
                  control={control} />
              </td>
            </tr>
            <tr>
              <td>
                <b>Prezzo manuale</b>
              </td>
              <td>
                <FormField
                  defaultValue={
                    defaultValues?.value
                      ? defaultValues?.value
                      : null
                  }
                  type="number"
                  register={register}
                  disabled={!editable}
                  name={"value"}
                  errorMessage={errors.value?.message}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <table className={classNames("table_col")}>
          <tbody>
            <tr>
              <td>
                <b>Tipo di trasporto</b>
              </td>
              <td>
                <SelectField
                  disabled={!editable}
                  placeholder="Tipo di trasporto"
                  options={optionsOrder.required_transport.choices}
                  register={register}
                  errorMessage={errors.required_transport?.message}
                  name="required_transport"
                  required
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>N. ordine</b>
              </td>
              <td>
                <FormField
                  defaultValue={
                    defaultValues?.order_number
                      ? defaultValues?.order_number
                      : null
                  }
                  type={"text"}
                  register={register}
                  disabled={!editable}
                  name={"order_number"}
                  errorMessage={errors.order_number?.message}
                />
              </td>
            </tr>
            <tr>
              <td>
                <b>Note</b>
              </td>
              <td>
                <FormField
                  defaultValue={
                    defaultValues?.order_number
                      ? defaultValues?.order_number
                      : null
                  }
                  type={"textarea"}
                  register={register}
                  disabled={!editable}
                  name={"notes"}
                  errorMessage={errors.notes?.message}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-1">
        <div className={"d-flex flex-row justify-content-justify"}>
          <div>
            <h3>Carico/scarichi</h3>
          </div>
          <div className={"justify-content-end"}>
            <CheckPermissions permissions={["rosina.change_order"]}>
              <Button
                size="sm"
                color="primary"
                className="mb-3"
                onClick={() =>
                  append({
                    driver_id: null,
                  })
                }
              >
                <span></span> + <span></span>
              </Button>
            </CheckPermissions>
          </div>
        </div>
        <ListGroup>
          {fields.map((item, index) => {
            return (
              <ListGroupItem key={item.key}
                style={{ backgroundColor: index === 0 ? "rgba(255, 0, 0, 0.12)" : "rgba(0, 255, 0, 0.12" }}
              >
                <h3>{index === 0 ? "Carico" : "Scarico"}</h3>
                <input
                  defaultValue={item.id}
                  type="hidden"
                  ref={register()}
                  name={`orderpoints[${index}].id`}
                />
                <div
                  className="d-flex flex-column flex-lg-row justify-content-between"

                >

                  <hr />
                  <table className={classNames("table_col_4", "mb-2")}>
                    <tbody>
                      <tr>
                        <td>
                          <b>Luogo </b>
                        </td>
                        <td>
                          <FormField
                            defaultValue={
                              defaultValues?.orderpoints?.[index]?.place
                                ? defaultValues?.orderpoints?.[index]?.place
                                : null
                            }
                            type={"text"}
                            register={register}
                            disabled={!editable}
                            name={`orderpoints[${index}].place`}
                            errorMessage={errors.orderpoints?.[index]?.place.message}
                          />
                        </td>
                        <td>
                          <b>Provincia </b>
                        </td>
                        <td>
                          <FormField
                            defaultValue={
                              defaultValues?.orderpoints?.[index]?.province
                                ? defaultValues?.orderpoints?.[index]?.province
                                : null
                            }
                            type={"text"}
                            register={register}
                            disabled={!editable}
                            name={`orderpoints[${index}].province`}
                            errorMessage={errors.orderpoints?.[index]?.province.message}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Data</b>
                        </td>
                        <td>
                          <DateTimeField
                            defaultValue={defaultValues?.orderpoints?.[index]?.date ? defaultValues?.orderpoints?.[index]?.date : null}
                            disabled={!editable}
                            name={`orderpoints[${index}].date`}
                            errorMessage={errors.orderpoints?.[index]?.date?.message}
                            control={control} />
                        </td>
                        <td>
                          <b>Slot (minuti)</b>
                        </td>
                        <td>
                          <FormField
                            defaultValue={
                              defaultValues?.orderpoints?.[index]?.minutes ? defaultValues?.orderpoints?.[index]?.minutes : null
                            }
                            type="number"
                            register={register}
                            disabled={!editable}
                            name={`orderpoints[${index}].minutes`}
                            errorMessage={errors.orderpoints?.[index]?.minutes?.message}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className={classNames("table_col_4", "mb-2")}>
                    <tbody>
                      <tr>
                        <td>
                          <b>Autista</b>
                        </td>
                        <td>
                          <Autocomplete
                            url={AUTOCOMPLETE_URLS.DRIVER}
                            defaultValue={
                              item.driver_id
                                ? item.driver_id
                                : null
                            }
                            disabled={!editable}
                            name={`orderpoints[${index}].driver_id`}
                            control={control}
                            errorMessage={
                              errors.orderpoints?.[index]?.driver_id
                                ?.message
                            }
                          />
                        </td>
                        <td>
                          <b>Targa rimorchio</b>
                        </td>
                        <td>
                          <FormField
                            defaultValue={
                              defaultValues?.orderpoints?.[index]?.plate
                                ? defaultValues?.orderpoints?.[index]?.plate
                                : null
                            }
                            type={"text"}
                            register={register}
                            disabled={!editable}
                            name={`orderpoints[${index}].plate`}
                            errorMessage={errors.orderpoints?.[index]?.plate.message}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Note</b>
                        </td>
                        <td>
                          <FormField
                            defaultValue={
                              defaultValues?.orderpoints?.[index]?.notes
                                ? defaultValues?.orderpoints?.[index]?.notes
                                : null
                            }
                            type={"textarea"}
                            register={register}
                            disabled={!editable}
                            name={`orderpoints[${index}].notes`}
                            errorMessage={errors.orderpoints?.[index]?.notes.message}
                          />
                        </td>
                        <td>
                          <b>Sgancio</b>
                        </td>
                        <td>
                          <FormField
                            defaultValue={
                              defaultValues?.orderpoints?.[index]?.unload_plate
                                ? defaultValues?.orderpoints?.[index]?.unload_plate
                                : null
                            }
                            type={"textarea"}
                            register={register}
                            disabled={!editable}
                            name={`orderpoints[${index}].unload_plate`}
                            errorMessage={errors.orderpoints?.[index]?.unload_plate.message}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {index >= 1 && (
                  <CheckPermissions permissions={["rosina.change_order"]}>
                    <div className="d-flex flex-row justify-content-end">
                      <Button
                        type="button"
                        size="sm"
                        color="danger"
                        onClick={() => remove(index)}
                      >
                        Rimuovi
                      </Button>
                    </div>
                  </CheckPermissions>
                )}
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </div >

    </>
  ) : null;
};
