import { NestedValue, useFieldArray, useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { UseMutateAsyncFunction } from "react-query";
import { toast } from "react-toastify";
import { addServerErrors } from "utils/helpers";
import { OrderForm } from "./OrderForm";
import { OrderPoint, Order } from "Traction/types";
import { useOptionsOrder } from "Traction/hooks/orders";

interface Props {
  submitFunction: UseMutateAsyncFunction<any, unknown, Order, unknown>;
}

export const AddOrderForm = ({ submitFunction }: Props) => {
  const {
    errors,
    register,
    handleSubmit,
    setError,
    control,
    getValues,
    watch,
  } = useForm<
    Omit<Order, "client" | "orderpoints"> & {
      orderpoints: NestedValue<OrderPoint[]>;
    }
  >({
    defaultValues: {
      client_id: undefined,
      orderpoints: [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "orderpoints",
    keyName: "key" as "id",
  });
  const { data: options } = useOptionsOrder();

  const onSend = handleSubmit((data) => {
    console.log(data);
    submitFunction(data)
      .then(() => {
        toast.success("Ordine aggiunto!");
      })
      .catch((errors) => {
        const data = errors.response.data;
        addServerErrors(data, setError);
      });
  });
  return (
    <Form onSubmit={onSend}>
      {options && (
        <OrderForm
          watch={watch}
          options={options}
          append={append}
          errors={errors}
          control={control}
          register={register}
          getValues={getValues}
          fields={fields}
          remove={remove}
        />
      )}

      <div className="d-flex justify-content-end py-3">
        <Button type="submit" color="success">
          Salva
        </Button>
      </div>
    </Form>
  );
};
