import {
  Archive,
  DollarSign,
  FastForward,
  Map,
  Navigation,
  Send,
  Truck,
  Users,
  File,
  Activity,
  Box,
  Navigation2,
  Trello,
  List,
  Home
} from "react-feather";
import { DeliveryPlanner } from "DeliveryPlanner";
import { WarehousePlanner } from "WarehousePlanner";
import { Deliveries } from "Distribution/views/Deliveries";
import { DeliveriesByAreas } from "Distribution/views/DeliveriesByAreas";
import { Routes } from "Distribution/views/Routes";
import { Notes } from "Distribution/views/Notes";
import { DefaultLayout } from "components/layouts/DefaultLayout";
import { Vehicles as VehiclesPage } from "Vehicles/views/Vehicles";
import { RouteLayout } from "shared/types";
import { Trailers } from "Vehicles/views/Trailers";
import { Drivers as DriversPage } from "Drivers/views/Drivers";
import { HeaderLayout } from "components/layouts/HeaderLayout";
import { ItemsPage } from "Items/views/ItemsPage";
import { Prices } from "Items/views/Prices";
import { SubjectsPage } from "Subjects/views/SubjectsPage";
import { NewSubject } from "Subjects/views/NewSubject";
import { SubjectPage } from "Subjects/views/SubjectPage";
import { CalculateInvoices } from "Billing/views/CalculateInvoices/CalculateInvoices";
import { Invoices } from "Billing/views/Invoices";
import { Charges } from "Billing/views/Charges";
import { Orders } from "Traction/views/Orders";
import { TractionPlanner } from "Traction/views/TractionPlanner";
import { HistoryImport } from "Import/views/HistoryImport";
import { Upload } from "Import/views/Upload";
import { SubjectsSolver } from "Import/views/SubjectsSolver";
import { FuelConsumptions } from "FuelConspumption/views/FuelConsumption";
import { FuelConsumptionFiles } from "FuelConspumption/views/FuelConsumptionFiles";
import { FuelSites } from "FuelConspumption/views/FuelSite";
import { Pallets } from "PalletsManagment/views/Pallets";
import { PalletTransactions } from "PalletsManagment/views/PalletTransactions";
import { PalletsSubject } from "PalletsManagment/views/PalletsSubject";
import { Segments } from "Traction/views/Segments";
import { Floors } from "Warehouse/Floor/views/Floors";
import { ProductTypeForm } from "Warehouse/ProductType/components/ProductTypeForm";
import { ProductType } from "Warehouse/ProductType/views/ProductType";
import { Places } from "Warehouse/Place/views/Places";
import { Product } from "Warehouse/Product/views/Product";
import { Article } from "Warehouse/Article/views/Article";
import { Status } from "Warehouse/Status/views/Status";
import { HomePage } from "HomePage/HomePage";
import { OrderPoints } from "Traction/views/OrderPoints";

export const routes: RouteLayout[] = [
  {
    layout: DefaultLayout,
    subRoutes: [
      {
        path: "/home",
        name: "Home",
        icon: Home,
        component: HomePage,
        permissions: [],
      },
    ],
  },
  {
    subRoutes: [
      {
        path: "/distribution/planner",
        name: "Pianificatore",
        icon: Map,
        component: DeliveryPlanner,
        permissions: ["rosina.change_route"],
      },
    ],
  },
  {
    layout: DefaultLayout,
    subRoutes: [

      {
        path: "/distribution",
        name: "Distribuzione",
        icon: Navigation,
        permissions: [
          "rosina.view_delivery",
          "rosina.view_route",
          "rosina.view_note",
        ],
        component: HeaderLayout,
        children: [
          {
            path: "/distribution",
            exact: true,
            name: "Missioni",
            permissions: ["rosina.view_delivery"],
            component: Deliveries,
          },
          {
            path: "/distribution/deliveries/areas",
            exact: true,
            permissions: ["rosina.view_delivery"],
            name: "Missioni per zone",
            component: DeliveriesByAreas,
          },
          {
            path: "/distribution/routes",
            permissions: ["rosina.view_route"],
            name: "Giri",
            component: Routes,
          },
          {
            path: "/distribution/notes",
            name: "Bolle",
            permissions: ["rosina.view_note"],

            component: Notes,
          },
        ],
      },
      {
        path: "/import",
        name: "Import",
        icon: File,
        component: HeaderLayout,
        permissions: ["rosina.view_importedfile", "rosina.change_importedfile"],
        children: [
          {
            path: "/import",
            exact: true,
            name: "Storico",
            component: HistoryImport,
            permissions: ["rosina.view_importedfile"],
          },
          {
            path: "/import/new",
            name: "Carica",
            component: Upload,
            permissions: ["rosina.change_importedfile"],
          },
          {
            path: "/import/solver",
            name: "Risolutore",
            component: SubjectsSolver,
            permissions: ["rosina.change_importedfile"],
          },
        ],
      },
      {
        path: "/resources",
        name: "Anagrafiche",
        icon: Users,
        permissions: [
          "rosina.view_subject", "rosina.view_driver",
          "rosina.view_vehicle", "rosina.view_trailer"],
        component: HeaderLayout,
        children: [
          {
            path: "/resources/subjects",
            exact: true,
            name: "Soggetti",
            permissions: ["rosina.view_subject"],

            component: SubjectsPage,
          },
          {
            path: "/resources/subjects/new",
            exact: true,
            name: "Aggiungi Soggetti",
            permissions: ["rosina.change_subject"],
            component: NewSubject,
          },
          {
            path: "/resources/subjects/:id",
            exact: true,
            hideHeader: true,
            name: "Dettaglio",
            permissions: ["rosina.view_subject"],
            component: SubjectPage,
          },
          {
            path: "/resources/drivers",
            name: "Autisti",
            exact: true,
            //icon: Send,
            permissions: ["rosina.view_driver"],
            component: DriversPage,
          },
          {
            path: "/resources/vehicles",
            exact: true,
            name: "Veicoli",
            permissions: ["rosina.view_vehicle"],

            component: VehiclesPage,
          },
          {
            path: "/resources/trailers",
            exact: true,
            name: "Rimorchi",
            permissions: ["rosina.view_trailer"],
            component: Trailers,
          },
        ],
      },
      {
        path: "/billing",
        name: "Fatturazione",
        icon: DollarSign,
        permissions: [
          "rosina.view_invoice", "rosina.view_charge",
          "rosina.view_item", "rosina.view_price"],
        component: HeaderLayout,
        children: [
          {
            path: "/billing/items",
            exact: true,
            name: "Articoli",
            component: ItemsPage,
            permissions: ["rosina.view_item"],
          },
          {
            path: "/billing/prices",
            exact: true,
            name: "Tariffe",
            component: Prices,
            permissions: ["rosina.view_price"],
          },
          {
            path: "/billing/billing",
            exact: true,
            name: "Calcolo fatture",
            permissions: ["rosina.change_invoice"],
            component: CalculateInvoices,
          },
          {
            path: "/billing/invoices",
            name: "Fatture",
            permissions: ["rosina.view_invoice"],
            component: Invoices,
          },
          {
            path: "/billing/charges",
            exact: true,
            name: "Addebiti",
            permissions: ["rosina.view_charge"],
            component: Charges,
          },
        ],
      },
      {
        path: "/traction",
        name: "Trazioni",
        icon: FastForward,
        component: HeaderLayout,
        permissions: [
          "rosina.view_order",
          "rosina.view_segment",
          "rosina.change_destinytraction",
        ],
        children: [
          {
            path: "/traction",
            exact: true,
            name: "Ordini",
            component: Orders,
            permissions: ["rosina.view_order"],
            /*
          },
          {
          {
            path: "/traction/planner",
            name: "Pianificatore trazioni",
            //icon: Navigation2,
            component: TractionPlanner,
            permissions: ["rosina.change_destinytraction"],
          },
          
          {
            path: "/traction/segments",
            exact: true,
            name: "Tratte",
            component: Segments,
            permissions: ["rosina.view_segment"],
          },
          {
            path: "/traction/planner",
            name: "Pianificatore",
            component: TractionPlanner,
            permissions: ["rosina.change_destinytraction"],
            },
            */},
          {
            path: "/traction/orderpoints",
            exact: true,
            name: "Carichi/Scarichi",
            component: OrderPoints,
            permissions: ["rosina.view_order"],
          },
        ],
      },
      {
        path: "/pallets",
        name: "Gestione pallet",
        icon: Box,
        component: HeaderLayout,
        permissions: ["rosina.view_pallet", "rosina.view_destinytraction"],
        children: [
          {
            path: "/pallets",
            exact: true,
            name: "Pallet",
            component: Pallets,
            permissions: ["rosina.view_pallet"],
          },
          {
            path: "/pallets/transactions",
            name: "Transazioni pallet",
            component: PalletTransactions,
            permissions: ["rosina.view_destinytraction"],
          },
          {
            path: "/pallets/subject-report",
            name: "Transazioni pallet soggetto",
            component: PalletsSubject,
            permissions: ["rosina.view_destinytraction"],
          },
        ],
      },
      {
        path: "/fuel-consumption",
        name: "Consumo carburante",
        icon: Activity,
        component: HeaderLayout,
        permissions: [
          "rosina.view_fuelconsumption",
          "rosina.view_fuelconsumptionfile",
          "rosina.view_fuelsite",
        ],
        children: [
          {
            path: "/fuel-consumption",
            exact: true,
            name: "Consumo",
            component: FuelConsumptions,
            permissions: ["rosina.view_fuelconsumption"],
          },
          {
            path: "/fuel-consumption/files",
            name: "File importati",
            component: FuelConsumptionFiles,
            permissions: ["rosina.view_fuelconsumptionfile"],
          },
          {
            path: "/fuel-consumption/sites",
            name: "Fornitori",
            component: FuelSites,
            permissions: ["rosina.view_fuelsite"],
          },
        ],
      },

    ],
  },
  {
    subRoutes: [
      {
        path: "/warehouse/planner",
        name: "Planimetria magazzino",
        icon: Trello,
        component: WarehousePlanner,
        permissions: ["rosina.change_sector"],
      },
      {
        path: "/warehouse/status",
        name: "Gestione magazzino",
        icon: Trello,
        component: Status,
        permissions: ["rosina.change_status"],
      },
    ],
  },
  {
    layout: DefaultLayout,
    subRoutes: [

      {
        path: "/warehouse",
        name: "Magazzino",
        icon: List,
        permissions: [
          "rosina.view_floor",
          "rosina.view_sector",
          "rosina.view_row",
        ],
        component: HeaderLayout,
        children: [
          {
            path: "/warehouse/product_types",
            name: "Tipi prodotti",
            component: ProductType,
            permissions: ["rosina.change_producttype"],
          },
          {
            path: "/warehouse/products",
            name: "Prodotti",
            component: Product,
            permissions: ["rosina.change_product"],
          },
          {
            path: "/warehouse/articles",
            name: "Articoli",
            component: Article,
            permissions: ["rosina.change_article"],
          },
          {
            path: "/warehouse/floor",
            name: "Piani",
            component: Floors,
            permissions: ["rosina.change_floor"],
          },
          {
            path: "/warehouse/place",
            name: "Posti",
            component: Places,
            permissions: ["rosina.change_place"],
          },
        ],
      },
    ],
  },
];
