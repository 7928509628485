import { UseQueryOptions } from "react-query";
import { useAdd } from "shared/hooks/useAdd";
import { useDelete } from "shared/hooks/useDelete";
import { useGetItem } from "shared/hooks/useGetItem";
import { useList } from "shared/hooks/useList";
import { useOptions } from "shared/hooks/useOptions";
import { useSearchPaginated } from "shared/hooks/useSearchPaginated";
import { useUpdate } from "shared/hooks/useUpdate";
import { AUTOCOMPLETE_URLS, ORDER_POINTS_API } from "utils/constants";
import { OrderPoint } from "Traction/types";
import { useMemo } from "react";
import { Button, Input } from "reactstrap";
import { getDisplayDate, getDisplayValue } from "utils/helpers";
import { useGlobalModals } from "shared/context/globalModals";
import { DeleteButton } from "components/buttons/DeleteButton";
import { filterJson } from "components/tables/filters";
import { FormField } from "components/forms/FormField";
import { InputColumnCell } from "components/forms/InputColumnCell";
import { Copy, Package } from "react-feather";
import { IconButtonPermission } from "components/buttons/IconButtonPermission";
import { toast } from "react-toastify";

const key = "orderpoints";
const url = ORDER_POINTS_API;

export function useOptionsOrderPoint() {
  return useOptions(url, key);
}

export function useAddOrderPoint() {
  return useAdd<OrderPoint>(url, key);
}

export function useDeleteOrderPoint() {
  return useDelete(url, key);
}

export function useUpdateOrderPoint() {
  return useUpdate<OrderPoint>(url, key);
}

export function useListOrderPoint(
  params?: any,
  config?: UseQueryOptions<any, unknown, OrderPoint[]>
) {
  return useList(url, key, params, config);
}

export function useSearchOrderPoint(searchParams?: any) {
  return useSearchPaginated<OrderPoint>(url, key, searchParams);
}

export function useOrderPoint(
  id: string | number,
  config?: UseQueryOptions<any, unknown, OrderPoint>
) {
  return useGetItem<OrderPoint>(id, url, key, config);
}

export function useOrderPointColumns(props: any) {
  const { setActiveItem } = useGlobalModals();
  const { mutate: deleteOrderPoint } = useDeleteOrderPoint();
  const { mutate: updateOrderPoint } = useUpdateOrderPoint();
  const options = props.options;

  return useMemo(() => {
    const onDelete = (id: number) => {
      var r = window.confirm("Rimuovere elemento?");
      if (r) deleteOrderPoint(id);
    };
    return [
      {
        accessor: "__id",
        Header: "",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <>
              <DeleteButton
                className="ml-2"
                permissions={["rosina.change_order"]}
                onClick={() => {
                  onDelete(cell.row.original.id);
                }}
              />
              <IconButtonPermission
                title="Elimina"
                color="info"
                Icon={Copy}
                {...props}
                className="ml-2"
                permissions={["rosina.change_order"]}
                onClick={() => {
                  let text = "ORDINE "
                  if (cell.row.original.order_point_type == "L") {
                    text = text + `*CARICO*`
                  }
                  else {
                    text = text + `*SCARICO*`
                  }

                  text = text + ` 
PER CLIENTE *${cell.row.original.order_object.client.business_name}* 
IN DATA  *${getDisplayDate(cell.row.original.date)}*
PRESSO *${cell.row.original.place} (${cell.row.original.province})*
RIMORCHIO *${cell.row.original.plate}*
N°ORDINE *${cell.row.original.order_object.order_number} / ${cell.row.original.document_number}*`

                  navigator.clipboard.writeText(text)

                  toast.success("Copiato negli appunti!");
                }}
              />
              <IconButtonPermission
                title="Add pallet"
                color="info"
                Icon={Package}
                {...props}
                className="ml-2"
                permissions={["rosina.change_order"]}
                onClick={() => {

                }}
              />
            </>
          );
        },
      },
      {
        Header: "Tipo",
        accessor: "order_point_type",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.order_point_type == "L") {
            return <b style={{ backgroundColor: "rgb(155, 0, 0, 0.24)" }}>Carico</b>;
          }
          return <b style={{ backgroundColor: "rgb(0, 255, 0, 0.24)" }}>Scarico</b>
        },
        filter: filterJson
      },
      {
        Header: "Cliente",
        accessor: "order_object_client",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.order_object)
            return (
              <Button
                color="link"
                onClick={() => setActiveItem("subject", cell.row.original.order_object.client.id)}
              >
                {`${cell.row.original.order_object.client.id}:${cell.row.original.order_object.client.business_name}`}
              </Button>
            );

          return ""; //cell.row.original.order_object ? cell.row.original.order_object.order : "";
        },
        filter: filterJson
      },
      {
        Header: "Ordine",
        accessor: "order_object",
        sortType: "basic",
        Cell: (cell: any) => {
          if (cell.row.original.order_object)
            return (
              <Button
                color="link"
                onClick={() => setActiveItem("order", cell.row.original.order_object.id)}
              >
                {`${cell.row.original.order_object.id}:${cell.row.original.order_object.order_number}`}
              </Button>
            );

          return ""; //cell.row.original.order_object ? cell.row.original.order_object.order : "";
        },
        filter: filterJson
      },
      {
        Header: "Data",
        accessor: "date",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <InputColumnCell
              key_name="date"
              id={cell.row.original.id}
              value={cell.row.original.date}
              updateInline={updateOrderPoint}
              type={"date"}
              width="100px"
            />
          )
        },
        filter: filterJson
      },
      {
        Header: "Slot",
        accessor: "minutes",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <InputColumnCell
              key_name={"minutes"}
              id={cell.row.original.id}
              value={cell.row.original.minutes}
              updateInline={updateOrderPoint}
              type={"time"}
              width="50px"
            />
          )
        },
        filter: filterJson
      },
      {
        Header: "PR",
        accessor: "province",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <InputColumnCell
              key_name={"province"}
              id={cell.row.original.id}
              value={cell.row.original.province}
              updateInline={updateOrderPoint}
              type={"text"}
              width="50px"
            />
          )
        },
        filter: filterJson
      },
      {
        Header: "Luogo",
        accessor: "place",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <InputColumnCell
              key_name={"place"}
              id={cell.row.original.id}
              value={cell.row.original.place}
              updateInline={updateOrderPoint}
              type={"text"}
            />
          )
        },
        filter: filterJson
      },
      {
        Header: "N. documento",
        accessor: "document_number",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <InputColumnCell
              key_name={"document_number"}
              id={cell.row.original.id}
              value={cell.row.original.document_number}
              updateInline={updateOrderPoint}
              type={"text"}
            />
          )
        },
        filter: filterJson
      },
      {
        Header: "Autista",
        accessor: "driver",
        sortType: "basic",
        Cell: (cell: any) => {
          return <InputColumnCell
            key_name={"driver_id"}
            id={cell.row.original.id}
            value={cell.row.original.driver_id}
            updateInline={updateOrderPoint}
            type={"autocomplete"}
            url={AUTOCOMPLETE_URLS.DRIVER}
          />
        },
        filter: filterJson
      },
      {
        Header: "Rimorchio",
        accessor: "plate",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <InputColumnCell
              key_name={"plate"}
              id={cell.row.original.id}
              value={cell.row.original.plate}
              updateInline={updateOrderPoint}
              type={"text"}
              width="70px"
            />
          )
        },
        filter: filterJson
      },
      {
        Header: "Sgancio rimorichio",
        accessor: "unload_plate",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <InputColumnCell
              key_name={"unload_plate"}
              id={cell.row.original.id}
              value={cell.row.original.unload_plate}
              updateInline={updateOrderPoint}
              type={"text"}
            />
          )
        },
        filter: filterJson
      },
      {
        Header: "Note",
        accessor: "notes",
        sortType: "basic",
        Cell: (cell: any) => {
          return (
            <InputColumnCell
              key_name={"notes"}
              id={cell.row.original.id}
              value={cell.row.original.notes}
              updateInline={updateOrderPoint}
              type={"text"}
            />
          )
        },
        filter: filterJson
      },

    ];
  }, [deleteOrderPoint, updateOrderPoint, setActiveItem]);
}
